<div class="container">
  <div class="row">
    <div class="col-9">
      <h2><button [routerLink]="['/dashboard']"  mat-raised-button >Dashboard</button> API Data</h2>  
    </div>
    <div class="col-3 text-right">
      <mat-form-field  class="full-width" appearance="standard">

      
        <mat-select [(value)]="selected" (valueChange)="changeValue($event)">
 
          <mat-option *ngFor="let food of foods" [value]="food.value">
            {{food.viewValue}} 
          </mat-option>
        </mat-select>
        
      </mat-form-field>
    </div>
  </div></div>
  <div class="container containerWide">
<div class="example-container mat-elevation-z8">
    <div class="example-loading-shade"
         *ngIf="isLoadingResults || isRateLimitReached">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
 
    </div>
  
    <div class="the-table-container">
  
      <mat-table #table [dataSource]="data" class="example-table" matSort matSortActive="id" matSortDisableClear matSortDirection="desc">

      <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
        <mat-header-cell  mat-sort-header *matHeaderCellDef>{{ disCol.replace("_"," ").replace("_"," ") | titlecase }}</mat-header-cell>
        <mat-cell *matCellDef="let element "> {{element[disCol]}} 
        </mat-cell>
    </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>


    </div>
  
    <mat-paginator [length]="resultsLength" [pageSize]="7"></mat-paginator>
  </div>

</div>