import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { first } from 'rxjs/operators';


 

import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
 
import { HttpClient } from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';

 
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import{ BadgeService } from './../badge.service';



export interface farmDashboard {

  fieldarea: string;
  typeofirrigation: string;
  crop: string;
  cropvariety: string;
  Age: string;
  targetcrop: string;

  TotalETomm: string, 
  TotalRainmm: string, 
  TotalIrrigationEvents: string, 
  TotalIrrigationRainfallmm: string, 
  TotalIrrigationinCropCyclemm: string, 
  TotalWaterpumpedMLFieldha: string, 
  TotalWaterPumpedMLha: string, 
  TotalWaterpumpedmmm2: string, 
  WaterAppliedPreWettingmm: string, 
  TotalWaterreceivedinSoilmm: string, 
  TotalCropWaterUsedmm: string, 
  TotalDrainageWatermm: string, 
  Totalcropstressdays: string, 
  Yieldtha: string, 
  WaterProductivityDmm: string, 
  WaterProductivityTmm: string

  FixedCostsTotal: string, 
  VariableCostsTotal: string, 
  HiddenCostsTotal: string, 
  TotalCosts: string, 
  TotalYield: string, 
  Priceperton: string, 
  TotalWaterCosts: string, 
  TotalElectricityCharge: string, 
  TotalCostperIrrigationEvent: string, 
  Revenue: string, 
  RevenuelessExpenses: string,
  lastirrigationcost: string, 
  irrigationcoststodate: string
}
 
const CropStats_DATA: farmDashboard[] = [
  {fieldarea: '28', typeofirrigation: 'Pivot', crop: 'Potato', cropvariety: 'Nectar', Age: '98 days', targetcrop: 'Fresh Market',TotalETomm: '328.7', 
  TotalRainmm: '49.2', 
  TotalIrrigationEvents: '118', 
  TotalIrrigationRainfallmm: '436', 
  TotalIrrigationinCropCyclemm: '387', 
  TotalWaterpumpedMLFieldha: '240',
  TotalWaterPumpedMLha: '8.571',
  TotalWaterpumpedmmm2: '857.1',
  WaterAppliedPreWettingmm: '470.1',
  TotalWaterreceivedinSoilmm: '369.2',
  TotalCropWaterUsedmm: '372.9',
  TotalDrainageWatermm: '0.61',
  Totalcropstressdays: '0',
  Yieldtha: '47',
  WaterProductivityDmm: '$1,064.29',
  WaterProductivityTmm: '0.1',
  FixedCostsTotal: '0', 
    VariableCostsTotal: '0', 
    HiddenCostsTotal: '0', 
    TotalCosts: '0', 
    TotalYield: '0', 
    Priceperton: '0', 
    TotalWaterCosts: '0', 
    TotalElectricityCharge: '0', 
    TotalCostperIrrigationEvent: '0', 
    Revenue: '0', 
    RevenuelessExpenses: '0',
    lastirrigationcost: '0',
    irrigationcoststodate: '0'
},
 ];
 
 

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  currentfieldid = 0;
  SelectedCrop = 0;
  displayAlerts: boolean = true;
  displayIrrigationCosts: boolean = false;
  displayedColumns: string[] = [];
  apidata: apidata | null;
  data: apidata[] = [];
  selectedapi: "";
  resultsLength = 0;
  admin: boolean = false;
  isLoadingResults = false;
  isRateLimitReached = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumnsCosts: string[] = ['lastirrigationcost', 'irrigationcoststodate'];
  displayedColumnsCrop: string[] = ['fieldarea', 'typeofirrigation', 'crop', 'cropvariety', 'Age', 'targetcrop'];
  displayedColumnsWater1: string[] = ['TotalETomm', 
  'TotalRainmm', 
  'TotalIrrigationEvents', 
  'TotalIrrigationRainfallmm', 
  'TotalIrrigationinCropCyclemm', 
  'TotalWaterpumpedMLFieldha',
  'TotalWaterPumpedMLha'];

  displayedColumnsWater2: string[] = [ 
  
  'WaterAppliedPreWettingmm',
  'TotalWaterreceivedinSoilmm',
  'TotalCropWaterUsedmm',
  'TotalDrainageWatermm',
  'Totalcropstressdays',
  'Yieldtha',
  'WaterProductivityDmm',
  'WaterProductivityTmm'];
  
  displayedColumnsFin: string[] = ['FixedCostsTotal', 
  'VariableCostsTotal', 
  'HiddenCostsTotal', 
  'TotalCosts', 
  'TotalYield', 
  'Priceperton', 
  'TotalWaterCosts', 
  'TotalElectricityCharge', 
  'TotalCostperIrrigationEvent', 
  'Revenue', 
  'RevenuelessExpenses'];

 dashboardDataSource  =CropStats_DATA;
 
 selected = [];
  crops = [{id:0,idSelected:12, farmname: 'loading', name: ''}];
  SelectedFielder = 0;
  displayDashboard = false;
 
 
  


  constructor(private dataService: ApiService,private _httpClient: HttpClient,private _snackBar: MatSnackBar , public BadgeService: BadgeService, private router: Router) { 

             //Check for Farm
             this.dataService.farmGet('').pipe(first()).subscribe(data => { 
              if (data.total_count == 0){
                this.router.navigate(['/farms/add']);
              }else{
           
      


    this.dataService.cropGet('')
    .pipe(first())
    .subscribe(
    data => { 
      
      if (data.total_count > 0){
        this.BadgeService.setBadgeCrops("");
        
        this.displayDashboard = true;
        this.crops = data.crops;
        this.currentfieldid = data.crops[0].id;

        if (localStorage.getItem('currentfieldid')){
            this.currentfieldid = parseInt(localStorage.getItem('currentfieldid'));
            for( var i=0; i<this.crops.length; i++) {
                 if(this.crops[i].id == this.currentfieldid){
                this.SelectedFielder = i;
                }
            }
           
        }
            
        this.SelectedCrop = this.currentfieldid;

        this.dataService.dashboardGetFarm(this.currentfieldid)
        .pipe(first())
        .subscribe(
        data => {
          this.getAlerts();
          this.dashboardDataSource = data.farms;
          
          this.changeValue(this.currentfieldid);
          
        });

      }else{

        ///redirdect to new crop
        this.router.navigate(['/fields/add']);

      }

    });
  }
});


  }
 
  compareIds(id1: any, id2: any): boolean {
debugger
    return true;
}

  ngOnInit(): void {



    
     
  

  }

getAlerts(){

  this.apidata = new apidata(this._httpClient);

 
  // If the crop changes the sort order, reset back to the first page.
  this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

  merge(this.sort.sortChange, this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = false;
        return this.apidata!.getapidata(
          this.sort.active, this.sort.direction, this.paginator.pageIndex);
      }),
      map(data => {


        if (data["error"] != null){
          window.location.href = '/#/login?returnUrl=%2Fdashboard';
        }
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;

        this.resultsLength = data.total_count;

  
        if (this.displayedColumns.length == 0){
        for (var index = 1; index < data.columns.length; ++index) {
          console.log(data.columns[index]);
          
          this.displayedColumns.push(data.columns[index]);
        }

        
      }
      
      if (data.total_count == 0){
        document.getElementById("AlertCard").style.display = "none";
        this.BadgeService.setBadgeDashboard("");
      }else{
        document.getElementById("AlertCard").style.display = "block";
        this.BadgeService.setBadgeDashboard(data.total_count.toString());
      }
      document.getElementById("MainCard").style.display = "block";
      


        return data.apidata;
      }),
      catchError(() => {
        this.isLoadingResults = false;

        window.location.href = '/#/login?returnUrl=%2Fdashboard';
        return observableOf([]);
      })
    ).subscribe(data => this.data = data);
}

  changeValue(value:any){
    this.currentfieldid =value;
    this.dataService.dashboardGetFarm(value)
      .pipe(first())
      .subscribe(
      data => {

        this.dashboardDataSource = data.farms;
        this.displayIrrigationCosts = false;
        if (data.farms[0].lastirrigationcost > 0){
          this.displayIrrigationCosts = true;
        }
      });
  }


 
  goToReports(){ 
    localStorage.setItem('currentfieldid', this.currentfieldid.toString());
    window.location.href = '/#/reports/';
  }
  goToField(){ 
    window.location.href = '/#/fields/' + this.currentfieldid ;
  }

  delete(item){
    // your delete code
    console.log(item.Id);
    this.dataService.alertHide(item.Id)
    .subscribe(
    data => {

    },
    error => {
    });

    this.getAlerts();

  }

}
export interface apidataApi {
  apidata: apidata[];
  columns: [];
  total_count: number;



}


export class apidata {
  constructor(private _httpClient: HttpClient) {}

  getapidata(sort: string, order: string, page: number): Observable<apidataApi> {

 
    const href = 'https://waterlink.ruciak.net/api/apidata.php';
    const requestUrl =
        `${href}?orderby=${sort}&orderdirection=${order}&pagesize=3&page=${page}&table=ALERTS`;
        

    return this._httpClient.get<apidataApi>(requestUrl,{ headers: {'token':localStorage.getItem('token')}});
  }
}