
  <mat-card style="margin:15px">
    <mat-card-header>
      <div style="width:100%">
<div class="side-images" style="float:left"><img src="/assets/water1.png" alt="image"  class="side-image"></div>
<div class="side-images" style="float:right"><img src="/assets/water2.png" alt="image"  class="side-image"></div>

<h1 class="text-center mt-5 Heading HeadingMain" >WaterLink </h1>
  <h2 class="text-center mt-5  Heading HeadingSub" >The integrated water productivity accounting tool</h2>

  

  <div class="text-center Heading"><img src="/assets/frontflow.png" alt="image" style="width: 70%;
  max-width: 800px;"></div>
<div class="text-center  container mt-5 " style="max-width:600px">


<form [formGroup]="angForm"  autocomplete="off" >



  
    <mat-form-field class="half-width">
        <mat-label>Email</mat-label>
        <input matInput   formControlName="email"  placeholder="Email">
      </mat-form-field>
 
      <mat-form-field class="half-width">
        <mat-label>{{lblPassword}}</mat-label>
        <input matInput  type="password"  formControlName="password"  >

      </mat-form-field>
      <br>
       <button mat-raised-button color="primary" (click)="Login()">Login</button>


       <button [routerLink]="['/registration']"  mat-raised-button >Register New User</button>
       <button mat-raised-button  (click)="ResetPassword()" >Reset Password</button>
     
          <br>      <br>

 



</form>

 
</div>  
<h3 class="text-center mt-3 mb-3   Heading Heading3" >
Linking Soil Moisture, Climate and Accounting for Improved Irrigation Decision Making and Water Productivity
</h3>


</div>
</mat-card-header>
</mat-card>




    <mat-card style="margin:15px">
      <mat-card-header>
        <div style="width:100%">
          <div class="side-images" style="float:left"><img src="/assets/sentek.png" alt="image" class="side-image-small"></div>
          <div class="side-images" style="float:right"><img src="/assets/unisa.png" alt="image"  class="side-image-small"></div>
        </div>
      </mat-card-header>
    </mat-card>

    <h5 style="width: 100%;
    text-align: center;

 ">version 20906.1</h5>

