<div class="container">

  <div class="row">
    <div class="col-9">
      <h2><button [routerLink]="['/dashboard']"  mat-raised-button >Dashboard</button> <button [routerLink]="['/seasons']" mat-raised-button >Seasons</button> {{mode}} Season</h2>
    </div>

  </div>

  

  

          
  <form [formGroup]="angForm"  autocomplete="off" >  



      <div class="jumbotron_not">

      <br>

        <mat-tab-group >
          <mat-tab label="Details" >
            <div class="col-12">
              <mat-form-field  class="full-width" appearance="standard">
                <mat-label>Farm</mat-label>
              
                <mat-select matNativeControl formControlName="farmid">
                  <mat-option></mat-option>
                  <mat-option *ngFor="let farm of farms" [value]="farm.id">{{farm.name}}</mat-option>
                </mat-select>
              </mat-form-field>
     
            </div>
        
            <div class="col-12 mt-3">
              <mat-form-field class="full-width"><mat-label>Name</mat-label><input matInput formControlName="name" placeholder="name"></mat-form-field>
              <button  *ngIf="displayFinanicals" mat-raised-button color="accent" (click)="saveform(false,false,true)" type="button" >Update costs to crops by size</button>
            </div>
            <div class="col-4 text-right">
      
              <button mat-raised-button color="primary"  (click)="saveform(false,true,false)">Save</button>
              <button mat-button [routerLink]="['/seasons']" type="button" >Cancel</button>
            </div>
          </mat-tab>
          <mat-tab label="Financials" *ngIf="displayFinanicals">

            <div class="Financials">
            <h2>Income</h2>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Yield - Tonnes</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="crop_yield" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Price $/tonne</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="crop_price" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="60px">
              <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
                <h3>Yield Income</h3><i>Revenue for specific harvest on this block/field</i></div>
              </mat-grid-tile>
              <mat-grid-tile><h3><mat-form-field><input matInput disabled id="income_yield" class="total right-align-forced"></mat-form-field>
                
              </h3></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced"><h3>Water Trade Income</h3><i>Any incomings from sales of water allocation during season </i></div></mat-grid-tile>
              <mat-grid-tile><h3><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"   matInput  formControlName="income_watertrade"></mat-form-field></h3></mat-grid-tile>
            </mat-grid-list>    
            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced"><h3>Total Income</h3></div></mat-grid-tile>
              <mat-grid-tile><h3><mat-form-field><input matInput disabled id="income_total" class="total right-align-forced"></mat-form-field></h3></mat-grid-tile>
            </mat-grid-list>    
            
            <h2>Water Related Direct Costs</h2>
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="4"><div class="left-align-forced">Setup Labour Costs<Br><i> Cost of irrigation labour for field/block setting up for this season  x hourly rate (eg moving pivot/lines, testing etc)</i></div></mat-grid-tile>
     
            </mat-grid-list>   

            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="costs_direct_water_setuplabour_owner" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="60px">
              <mat-grid-tile [colspan]="4"><div class="left-align-forced padleft"><i>Estimate of owner time lost on other activities, if unsure common measure is $150 per hour</i></div></mat-grid-tile>
               
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_setuplabour_permanent" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_setuplabour_casual" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input matInput disabled id="costs_direct_water_setuplabour_total" class="total right-align-forced"></mat-form-field>
                
              </mat-grid-tile>
            </mat-grid-list>  

            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="4"><div class="left-align-forced">In-season Labour Costs<br><i>Cost of irrigation labour for field/block during this season  x hourly rate (eg checking, turning pump on and off etc)</i></div></mat-grid-tile>
            </mat-grid-list>   

            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_seasonlabour_owner" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_seasonlabour_permanent" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_seasonlabour_casual" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input matInput disabled id="costs_direct_water_seasonlabour_total" class="total right-align-forced"></mat-form-field>
                
              </mat-grid-tile>
            </mat-grid-list>  

     

            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Water Costs<br><i>Cost of water used during the growing season for this field/block including any prewetting water applied</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_direct_water_costs"></mat-form-field></mat-grid-tile>
            </mat-grid-list>   

            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Fertiliser/Herbicide Costs	<br><i>Cost of fertiliser, manures, herbicides, fungicides or disease treatment  used on this field/block for this season</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_direct_water_treatment"></mat-form-field></mat-grid-tile>
            </mat-grid-list> 

            
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="4"><div class="left-align-forced">Fertiliser/Herbicide Application Costs <br><i>Cost of fertiliser, manures, herbicides, fungicides or disease treatment application labour related to this field/block for this season</i></div></mat-grid-tile>
            </mat-grid-list>   


            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_treatment_owner" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_treatment_permanent" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_treatment_casual" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input matInput disabled id="costs_direct_water_treatment_total" class="total right-align-forced"></mat-form-field>
                
              </mat-grid-tile>
            </mat-grid-list>  

            
              
              

            <mat-grid-list cols="4" rowHeight="60px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Pumping Costs	<br><i>Cost of power to run pump during season (including for any prewetting) </i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_direct_water_pumping"></mat-form-field></mat-grid-tile>
            </mat-grid-list>   
            <mat-grid-list cols="4" rowHeight="60px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Other Water Related Costs<br><i>eg water quality treatment, any other </i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_direct_water_other"></mat-form-field></mat-grid-tile>
            </mat-grid-list>   
            <mat-grid-list cols="4" rowHeight="60px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Machinery and Fuel Costs<br><i>Cost of fuel and vehicles used related to this field/block for this season</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_direct_water_fuel"></mat-form-field></mat-grid-tile>
            </mat-grid-list>                 

            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
                <h3>Direct Water Related Costs</h3></div>
              </mat-grid-tile>
              <mat-grid-tile><h3><mat-form-field><input matInput disabled id="costs_direct_total" class="total right-align-forced"></mat-form-field>
                
              </h3></mat-grid-tile>
            </mat-grid-list>

            <h2>Other Direct Costs</h2>
            <mat-grid-list cols="4" rowHeight="60px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Seed Costs<br><i>Cost to purchase seed for this season if applicable </i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_other_seed"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  



            <mat-grid-list cols="4" rowHeight="60px">
              <mat-grid-tile [colspan]="4"><div class="left-align-forced">Pruning Costs<br><i>Cost to prune this season if applicable </i></div></mat-grid-tile>
            </mat-grid-list>   	 

            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Machinery Hire Costs</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_pruning_hire" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_pruning_owner" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_pruning_permanent" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_pruning_casual" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input matInput disabled id="costs_other_pruning_total" class="total right-align-forced"></mat-form-field>
                
              </mat-grid-tile>
            </mat-grid-list>  



            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="4"><div class="left-align-forced">Harvest Costs<br></div></mat-grid-tile>
            </mat-grid-list>   	 

            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Machinery Hire Costs</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_harvest_hire" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_harvest_owner" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_harvest_permanent" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_harvest_casual" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input matInput disabled id="costs_other_harvest_total" class="total right-align-forced"></mat-form-field>
                
              </mat-grid-tile>
            </mat-grid-list>  
            
            <mat-grid-list cols="4" rowHeight="60px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Transport Costs<br><i>Cost of transport of harvested goods</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput formControlName="costs_other_transport"></mat-form-field></mat-grid-tile>
            </mat-grid-list>                 

            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
                <h3>Other Direct Costs</h3></div>
              </mat-grid-tile>
              <mat-grid-tile><h3><mat-form-field><input matInput disabled id="costs_other_total" class="total right-align-forced"></mat-form-field>
                
              </h3></mat-grid-tile>
            </mat-grid-list>

          <h2>Water Related Indirect Costs</h2>

          <mat-grid-list cols="4" rowHeight="80px">
            <mat-grid-tile [colspan]="3"><div class="left-align-forced">Electricty Charges<br><i>Electricity service connection/market charges and other electricity annual type admin fees for this growing period for this field/block</i></div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput formControlName="costs_indirect_electricity"></mat-form-field></mat-grid-tile>
          </mat-grid-list>  
          <mat-grid-list cols="4" rowHeight="80px">
            <mat-grid-tile [colspan]="3"><div class="left-align-forced">Fixed Water Costs <br><i>Fees and admin costs related to ontaining water, not dependent on how much is used., such as water license fees </i></div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_fixedwater"></mat-form-field></mat-grid-tile>
          </mat-grid-list>  
          <mat-grid-list cols="4" rowHeight="80px">
            <mat-grid-tile [colspan]="3"><div class="left-align-forced">Irrigation Infrastructure<br><i>Irrigation/water-related infrastructure investment for field/block during growing year, eg pipes, pumps, nozzles?</i></div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_irrigation"></mat-form-field></mat-grid-tile>
          </mat-grid-list>  
          <mat-grid-list cols="4" rowHeight="80px">
            <mat-grid-tile [colspan]="3"><div class="left-align-forced">Ongoing Depreciation<br><i>Ongoing depreciation costs on irrigation infrastructure in use in field/block this growing season</i></div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_ongoingdepreciation"></mat-form-field></mat-grid-tile>
          </mat-grid-list>  
          <mat-grid-list cols="4" rowHeight="80px">
            <mat-grid-tile [colspan]="3"><div class="left-align-forced">Interest<br><i>Interest on any loans/credit cards used for irrigation infrastructure used in this field/block</i></div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_interest"></mat-form-field></mat-grid-tile>
          </mat-grid-list>  
          <mat-grid-list cols="4" rowHeight="80px">
            <mat-grid-tile [colspan]="3"><div class="left-align-forced">Bank Fees<br><i>Cost of any transactions, credit cards or loan establishment for new infrastructure undertaken during growing period for irrigating this field/block</i></div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput  formControlName="costs_indirect_bankfees"></mat-form-field></mat-grid-tile>
          </mat-grid-list>  
          <mat-grid-list cols="4" rowHeight="80px">
            <mat-grid-tile [colspan]="3"><div class="left-align-forced">Insurance<br><i>Insurance expense for water-related infrastructure during growing year – pivots, pumps, pipes, etc</i></div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_insurance"></mat-form-field></mat-grid-tile>
          </mat-grid-list>  
          <mat-grid-list cols="4" rowHeight="80px">
            <mat-grid-tile [colspan]="3"><div class="left-align-forced">Repairs and Maintenance - Equipment <br><i>Equipment expense for repairs and maintenance on irrigation infrastructure</i></div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_maintenance_equipment"></mat-form-field></mat-grid-tile>
          </mat-grid-list>  
          <mat-grid-list cols="4" rowHeight="80px">
            <mat-grid-tile [colspan]="4"><div class="left-align-forced">Repairs and Maintenance - Labour<br><i>External contractor/supplier/owner labour cost for repairs and maintenance on irrigation infrastructure</i></div></mat-grid-tile>
          </mat-grid-list>   	 
          <mat-grid-list cols="4" rowHeight="30px">
            <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_indirect_maintenance_owner" ></mat-form-field></mat-grid-tile>
            <mat-grid-tile></mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="30px">
            <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_indirect_maintenance_permanent" ></mat-form-field></mat-grid-tile>
            <mat-grid-tile></mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="30px">
            <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_indirect_maintenance_casual" ></mat-form-field></mat-grid-tile>
            <mat-grid-tile></mat-grid-tile>
          </mat-grid-list>
          <mat-grid-list cols="4" rowHeight="30px">
            <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Contract worker</div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_indirect_maintenance_contractor" ></mat-form-field></mat-grid-tile>
            <mat-grid-tile></mat-grid-tile>
          </mat-grid-list>            
          <mat-grid-list cols="4" rowHeight="50px">
            <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
            <mat-grid-tile></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input matInput disabled id="costs_indirect_maintenance_total" class="total right-align-forced"></mat-form-field>
              
            </mat-grid-tile>
          </mat-grid-list>  


          <mat-grid-list cols="4" rowHeight="50px">
            <mat-grid-tile [colspan]="3"><div class="left-align-forced">Water Carting Costs<br><i>Costs of moving water around property if applicable</i></div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_watercarting"></mat-form-field></mat-grid-tile>
          </mat-grid-list>  
          <mat-grid-list cols="4" rowHeight="80px">
            <mat-grid-tile [colspan]="3"><div class="left-align-forced">Water Storage Costs<br><i>Dam or bore repairs, maintenance, compliance costs etc </i></div></mat-grid-tile>
            <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_waterstorage"></mat-form-field></mat-grid-tile>
          </mat-grid-list>  



          <mat-grid-list cols="4" rowHeight="50px">
            <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
              <h3>Water Related Indirect Costs</h3></div>
            </mat-grid-tile>
            <mat-grid-tile><h3><mat-form-field><input matInput disabled id="costs_indirect_total" class="total right-align-forced"></mat-form-field>
              
            </h3></mat-grid-tile>
          </mat-grid-list>

          <mat-grid-list cols="4" rowHeight="50px">
            <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
              <h2>Total Costs</h2></div>
            </mat-grid-tile>
            <mat-grid-tile><h2><mat-form-field><input matInput disabled id="costs_total" class="total right-align-forced"></mat-form-field>
              
            </h2></mat-grid-tile>
          </mat-grid-list>

          <mat-grid-list cols="4" rowHeight="50px">
            <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
              <h2>Income minus Total Costs</h2></div>
            </mat-grid-tile>
            <mat-grid-tile><h2><mat-form-field><input matInput disabled id="income_minus_costs" class="total right-align-forced"></mat-form-field>
              
            </h2></mat-grid-tile>
          </mat-grid-list>



          <mat-grid-list cols="4" rowHeight="50px">

          </mat-grid-list>


        </div>
          
          </mat-tab>
          <mat-tab label="Cost Tracking"  *ngIf="displayFinanicals">



              <mat-card class="card">
                <mat-card-header>
                   <mat-card-title>Fixed Costs</mat-card-title>
                </mat-card-header>
                <mat-form-field class="full-width"><mat-label>Depreciation Irrigation System</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="depreciationirrigationsystem"  placeholder="Depreciation Irrigation System"></mat-form-field>
                <mat-form-field class="full-width"><mat-label>Alloc/Licence</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="alloc_licence"  placeholder="Alloc/Licence"></mat-form-field>
                <mat-form-field class="full-width"><mat-label>Interest</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="interest"  placeholder="Interest"></mat-form-field>
                <mat-form-field class="full-width"><mat-label>Taxes and Insurance</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="taxesandinsurance"  placeholder="Taxes and Insurance"></mat-form-field>
                <mat-form-field class="full-width"><mat-label>Fixed Sub-Total</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput formControlName="fixedsubtotal"  placeholder="Fixed Sub-Total"></mat-form-field>
  
                
             
              </mat-card>

              <mat-card class="card">
                <mat-card-header>
                   <mat-card-title>Hidden Costs</mat-card-title>
                </mat-card-header>
                <mat-form-field class="full-width"><mat-label>Owner Labour -Set Up</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="ownerlaboursetup"  placeholder="Owner Labour -Set Up"></mat-form-field>
                <mat-form-field class="full-width"><mat-label>Owner Labour - InSeason</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="ownerlabourinseason"  placeholder="Owner Labour - InSeason"></mat-form-field>
                <mat-form-field class="full-width"><mat-label>Owner Labour - Fertilizer</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="ownerlabourfertilizer"  placeholder="Owner Labour - Fertilizer"></mat-form-field>
                <mat-form-field class="full-width"><mat-label>Water Quality Treatment</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="waterqualitytreatment"  placeholder="Water Quality Treatment"></mat-form-field>
                <mat-form-field class="full-width"><mat-label>Hidden Sub-Total</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="hiddensubtotal"  placeholder="Hidden Sub-Total"></mat-form-field>
              </mat-card>

              <mat-card class="card">
                <mat-card-header>
                   <mat-card-title>Variable Costs</mat-card-title>
                </mat-card-header>
              <mat-form-field class="full-width"><mat-label>Power Pre-wetting</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="powerprewetting"  placeholder="Power Pre-wetting"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Power in Season</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="powerinseason"  placeholder="Power in Season"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Water incl. Pre-Wetting</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="waterinclprewetting"  placeholder="Water incl. Pre-Wetting"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Fertilizer</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="fertilizer"  placeholder="Fertilizer"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Disease Treatment</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="diseasetreatment"  placeholder="Disease Treatment"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Hired Casual Labour - Set Up</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="hiredcasuallaboursetup"  placeholder="Hired Casual Labour - Set Up"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Hired Casual Labour In Season</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="hiredcasuallabourinseason"  placeholder="Hired Casual Labour In Season"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Hired Casual Labour - Fertilizer</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="hiredcasuallabourfertilizer"  placeholder="Hired Casual Labour - Fertilizer"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Maintenance and Repairs</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="maintenanceandrepairs"  placeholder="Maintenance and Repairs"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Variable Sub-Total</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="variablesubtotal"  placeholder="Variable Sub-Total"></mat-form-field>

            </mat-card>

   

            <mat-card class="card">
              <mat-card-header>
                 <mat-card-title>Total</mat-card-title>
              </mat-card-header>
              <mat-form-field class="full-width"><mat-label>Costs</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="totalcosts"  placeholder="Total Costs"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Yield - Tonnes</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="yield"  placeholder="Yield"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Price</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="price"  placeholder="Price"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Revenue</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="revenue"  placeholder="Revenue"></mat-form-field>
              <mat-form-field class="full-width"><mat-label>Revenue less Expenses</mat-label><input (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="revenuelessexpenses"  placeholder="Revenue less Expenses"></mat-form-field>
       </mat-card>

      </mat-tab>
      


      <mat-tab label="Admin"  *ngIf="mode!='Add'">

        <button mat-raised-button color="outline" (click)="saveform(true,true,false)">Copy Season</button>
        <br><br><button mat-raised-button color="outline" (click)="deleteform()">Delete Season</button>
        <br><br>
      </mat-tab>
    
    </mat-tab-group>
  




 
    
      </div>
    </form>
    </div>

