<div class="container">
<h2><button [routerLink]="['/dashboard']"  mat-raised-button >Dashboard</button> Users</h2>

<div class="example-container mat-elevation-z8">
    <div class="example-loading-shade"
         *ngIf="isLoadingResults || isRateLimitReached">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
 
    </div>
  
    <div class="the-table-container">
  
      <table mat-table [dataSource]="data" class="example-table"
             matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
        <!-- Number Column -->
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row">{{row.number}}</td>
        </ng-container>
  
        <!-- Title Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>id</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>
  
        <!-- State Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row">
            <a [title]="row.name + ' details'" [routerLink]="['/users', row.id]">
              {{ row.name }}
            </a>
             </td>
        </ng-container>
  
        <!-- Created Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let row">{{row.email}}</td>
        </ng-container>
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td mat-cell *matCellDef="let row">{{row.active}}</td>
        </ng-container>
        <ng-container matColumnDef="admin">
          <th mat-header-cell *matHeaderCellDef>Admin</th>
          <td mat-cell *matCellDef="let row">{{row.admin}}</td>
        </ng-container>
        <ng-container matColumnDef="lastlogin">
          <th mat-header-cell *matHeaderCellDef>Last Login</th>
          <td mat-cell *matCellDef="let row">{{row.lastlogin}}</td>
        </ng-container>        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  
    <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
  </div>
</div>
