import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import{ BadgeService } from './../badge.service';


@Component({
selector: 'app-login',
templateUrl: './login.component.html',
styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {

lblPassword : string = "Password"



angForm: FormGroup;
constructor(private fb: FormBuilder,private dataService: ApiService,private router:Router , public BadgeService: BadgeService) {




this.angForm = this.fb.group({
email: ['', [Validators.required,Validators.minLength(1), Validators.email]],
password: ['', Validators.required]
});
}

getParamValueQueryString( paramName ) {
    const url = window.location.href;
    let paramValue = "";
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
ngOnInit() {
    if (this.getParamValueQueryString('g') != ""){
        this.lblPassword = "Enter new password"
    }else{
        this.lblPassword = "Password"
    }
}

Login()
{



    this.dataService.userlogin(this.angForm.value.email,this.angForm.value.password, this.getParamValueQueryString('g'))
    .pipe(first())
    .subscribe(
    data => {
        
        document.cookie = "token=" + data[0].token;
        this.BadgeService.UpdateAdmin();
   
      
        
        const redirect = this.dataService.redirectUrl ? this.dataService.redirectUrl : '/dashboard';
        this.router.navigate([redirect]);

    },
    error => {
    alert("Email or password is incorrect")
    });
}
 
ResetPassword()
{
    this.dataService.userResetPassword(this.angForm.value.email)
    .pipe(first())
    .subscribe(
    data => {
        alert("Check your email for password reset instructions")
    },
    error => {
         alert("Email not found")
    });
}

}