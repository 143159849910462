  import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { UsersComponent } from './admin/users/users.component';
import { UsereditorComponent } from './admin/usereditor/usereditor.component';
import { FarmsComponent } from './admin/farms/farms.component';
import { FarmeditorComponent } from './admin/farmeditor/farmeditor.component';
import { CropsComponent } from './admin/crops/crops.component';
import { cropeditorComponent } from './admin/cropeditor/cropeditor.component';
import { apidataComponent } from './admin/apidata/apidata.component';
import { AuthguardGuard } from './authguard.guard';




const routes: Routes = [
{ path: '', component: LoginComponent },
{ path: 'login', component: LoginComponent },
{ path: 'home', component: HomeComponent },
{ path: 'registration', component: RegisterComponent },
{ path: 'dashboard', component: DashboardComponent,canActivate: [AuthguardGuard] },
{ path: 'reports', component: ReportsComponent,canActivate: [AuthguardGuard] },
{ path: 'apidata', component: apidataComponent,canActivate: [AuthguardGuard] },


{ path: 'farms/:id', component: FarmeditorComponent,canActivate: [AuthguardGuard] },
{ path: 'farms/add', component: FarmeditorComponent,canActivate: [AuthguardGuard] },
{ path: 'farms', component: FarmsComponent,canActivate: [AuthguardGuard] },


{ path: 'fields/add', component: cropeditorComponent,canActivate: [AuthguardGuard] },
{ path: 'fields', component: CropsComponent,canActivate: [AuthguardGuard] },
{ path: 'fields/:id', component: cropeditorComponent,canActivate: [AuthguardGuard] },
{ path: 'fields/:id/:refresh', component: cropeditorComponent,canActivate: [AuthguardGuard] },

{ path: 'users', component: UsersComponent,canActivate: [AuthguardGuard] },
{ path: 'users/:id', component: UsereditorComponent,canActivate: [AuthguardGuard] }


 
]

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
exports: [RouterModule]
})

export class AppRoutingModule { }