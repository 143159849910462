
/*
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { first } from 'rxjs/operators';



import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
 
import { HttpClient } from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
 
 

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Router } from '@angular/router';

 */
 
import {HttpClient} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';

import { first } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})

export class ReportsComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  

 
  selectedapi: "";
 
 
 
 

  SelectedCrop = 0;
  selectedGroup = 'Seasonal Weather Tracker';
  selected = 'Daily Air Temperature';
  DataTabIndex: number;
  graphurl: SafeUrl;
  graphLoaded: boolean;
  crops = [{id:0,idSelected:0, farmname: 'loading', name: ''}];
  displaySubReports: boolean = true;


  currentfieldid: string = '';
  displayAlerts: boolean = true;
  displayedColumns: string[] = [];
  apidata: apidata | null;
  data: apidata[] = [];
 
  api_userid: "";
  api_logger: "";

  resultsLength = 0;

  isLoadingResults = false;
  isNoData= false;
  isRateLimitReached = false;
 

  
  foods: Food[] = [

  ];

  groups: Group[] = [
    {value: 'Seasonal Crop & Water Tracker', viewValue: 'Seasonal Crop & Water Tracker'},
    {value: 'Seasonal Weather Tracker', viewValue: 'Seasonal Weather Tracker'},
    {value: 'Agro-Economic', viewValue: 'Agro-Economic'}    
  ];

  reportsSeasonalCropWaterTracker: Food[] = [

    {value: 'Rainfall Events', viewValue: 'Rainfall Events'},
    {value: 'Irrigation Events', viewValue: 'Irrigation Events'},
    {value: 'Summary Rainfall Irrigation Events', viewValue: 'Summary Rainfall & Irrigation Events'},
    {value: 'Onset of Crop Water Stress', viewValue: 'Onset of Crop Water Stress'},
    {value: 'Onset of Water Logging Stress', viewValue: 'Onset of Water Logging Stress'},
    {value: 'Root Zone Development', viewValue: 'Root Zone Development'},
    {value: 'Depth of Irrigation', viewValue: 'Depth of Irrigation / Rainfall'},
  //  {value: 'Depth of Rainfall', viewValue: 'Depth of Rainfall'},
  //  {value: 'Irrigation Analysis', viewValue: 'Irrigation Analysis'},
  //  {value: 'Summary Irrigation Analysis', viewValue: 'Summary Irrigation Analysis'}
  ];

  reportsSeasonalWeatherTracker: Food[] = [
    {value: 'Daily Air Temperature', viewValue: 'Daily Air Temperature'},
    {value: 'Daily ETo', viewValue: 'Daily ETo'},
    {value: 'Daily Soil Temperature', viewValue: 'Daily Soil Temperature'},
    {value: 'Daily Wind Speed', viewValue: 'Daily Wind Speed'},
    {value: 'Daily AVG Wind Direction', viewValue: 'Daily AVG Wind Direction'},
    {value: 'Daily Solar Radiation', viewValue: 'Daily Solar Radiation'},
    {value: 'Daily Relative Humidity', viewValue: 'Daily Relative Humidity'}
  ];
  
  reportsAgroEconomic: Food[] = [
    {value: 'Agro-Economic', viewValue: 'Agro-Economic'}
  ];
  
 
  transform(value:string): string {
    let first = value.substr(0,1).toUpperCase();
    return first + value.substr(1); 
  }
  async changeValueCrop(value:any){
    let selectedCrop:any;
    selectedCrop = this.crops.filter(item => item.id == value.value)[0]
    this.api_userid = selectedCrop.api_userid;
    this.api_logger = selectedCrop.api_logger;
    //this.selected  = this.foods[0].value;
    this.changeValue(this.selected);
  }
 
  async changeValue(value:any){
    
    this.graphLoaded = false;
    this.DataTabIndex = 0;
    let selectedItem:any;
    selectedItem = this.foods.filter(item => item.value == value)[0]
    this.selected = selectedItem.value;
    this.displayedColumns =  [];
    this.paginator.pageIndex = 0;
    this.sort.active = "id";
    this.sort.direction = "desc";
    localStorage.setItem('currentfieldid', this.SelectedCrop.toString());
    this.displayData();
    await this.sleep(1000);
    
 

    //https://www.irrimaxlive.com/#/u:4596/API-Waterlink/

    if (this.selected == 'Daily ETo'){
      //5: Daily total ETo measured by weather station 00203F0B (mm)
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:5";
    }else if (this.selected == 'Daily Air Temperature'){
      //8: Daily Maximum air temperature (Celsius)
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:8";
    }else if (this.selected == 'Daily Soil Temperature'){
      //7: Daily Maximum temperature at probe’s first/top sensor (Celsius), Daily Maximum temperature at probe’s second sensor (Celsius)
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:7";
    }else if (this.selected == 'Daily Wind Speed'){
      //11: Wind Speed (m/s)
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:11";
    }else if (this.selected == 'Daily AVG Wind Direction'){
      //12: Wind Direction (degrees)
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:12";
    }else if (this.selected == 'Daily Solar Radiation'){
      //9: Daily Average solar radiation (W/m2)
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:9";
    }else if (this.selected == 'Daily Relative Humidity'){
      //10: Daily Average humidity (%)
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:10";
    }else if (this.selected == 'Rainfall Events'){
      //4: Daily total of rainfall only, measured by weather station 00203F0B (mm)
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:4";
    }else if (this.selected == 'Irrigation Events'){
      // ??? this is calculated field so no graph?
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:6";
    }else if (this.selected == 'Summary Rainfall Irrigation Events'){
      //6: Number of irrigations on this day, Total duration of all irrigations (mins), Total amount of all irrigations (mm), Maximum depth reached by any irrigation (cm), Maximum depth of any root activity (cm)
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:6";
    }else if (this.selected == 'Onset of Crop Water Stress'){
      // new api soon
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + "-alerts.gt";
    }else if (this.selected == 'Onset of Water Logging Stress'){
      // new api soon
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + "-alerts.gt";
    }else if (this.selected == 'Root Zone Development'){
      // ???
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:6";
    }else if (this.selected == 'Depth of Irrigation'){
      // ???
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:6";
    }else if (this.selected == 'Depth of Rainfall'){
      // ???
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:6";
    }else if (this.selected == 'Irrigation Analysis'){
      // ???
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:6";
    }else if (this.selected == 'Summary Irrigation Analysis'){
      // ???
      this.graphurl = "https://www.irrimaxlive.com/?embed=3#/u:" + this.api_userid + "/API-Waterlink/" + this.api_logger + ".gt:6";
    } 
    
    console.log(this.graphurl);

 

  }
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  changeValueGroup(value:any){
    this.displaySubReports = true;
    document.getElementById("paginator").style.display = "block";
    if (this.groups.filter(item => item.value == value)[0].value == 'Seasonal Crop & Water Tracker'){
      this.foods = this.reportsSeasonalCropWaterTracker;
    }else if (this.groups.filter(item => item.value == value)[0].value == 'Seasonal Weather Tracker'){
      this.foods = this.reportsSeasonalWeatherTracker;
    }else{
      this.foods = this.reportsAgroEconomic;
      this.displaySubReports = false;
      document.getElementById("paginator").style.display = "none";
    }
   
    this.selected  = this.foods[0].value;
    
    this.changeValue(this.selected);

  }
  constructor(private _httpClient: HttpClient, private dataService: ApiService, private router: Router) {



   //Check for Farm
   this.dataService.farmGet('').pipe(first()).subscribe(data => { 
    if (data.total_count == 0){
      this.router.navigate(['/farms/add']);
    }else{
      //Check for Field
      this.dataService.cropGet('').pipe(first()).subscribe(data => { 
        if (data.total_count == 0){
          this.router.navigate(['/fields/add']);
        }else{
          document.getElementById("container").style.display = "block";
        }
      });
    }
  });


  
  this.dataService.cropGet('')
  .pipe(first())
  .subscribe(
  data => { 
    if (data.total_count > 0){
      this.crops = data.crops;

      this.SelectedCrop = data.crops[0].id;
      this.api_userid = data.crops[0].api_userid;
      this.api_logger = data.crops[0].api_logger;
      this.foods = this.reportsSeasonalWeatherTracker;
 
      if (localStorage.getItem('currentfieldid')){
        for (let i = 0; i < data.crops.length; i++) {
          if (data.crops[i].id == parseInt(localStorage.getItem('currentfieldid'))){
              this.SelectedCrop = data.crops[i].id;
              this.api_userid = data.crops[i].api_userid;
              this.api_logger = data.crops[i].api_logger;
          }
        }
      }

      this.changeValue(this.selected);

    }

  });



}

 
ngAfterViewInit(): void {



}

  displayData() {



    this.apidata = new apidata(this._httpClient);

    this.graphLoaded = true;
    // If the crop changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = false;
          return this.apidata!.getapidata(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.selected, this.SelectedCrop);
        }),
        map(data => {
          if (data["error"] != null){
            window.location.href = '/#/login?returnUrl=%2Fdashboard';
          }
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isNoData = false;
          this.resultsLength = data.total_count;
          if (this.resultsLength == 0){
            this.isNoData = true;
          }
          if (this.displayedColumns.length == 0){
            for (var index = 1; index < data.columns.length; ++index) {
              console.log(data.columns[index]);
              this.displayedColumns.push(data.columns[index]);
            }
          }

          for (var index = 1; index < data.apidata.length; ++index) {
            data.apidata[index]["Total"] ='';
            console.log(data.apidata[index]);
          }

 
          return data.apidata;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isNoData = true;
          window.location.href = '/#/login?returnUrl=%2Fdashboard';
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }


 
 
 
}
export interface apidataApi {
  apidata: apidata[];
  columns: [];
  total_count: number;



}

export interface Food {
  value: string;
  viewValue: string;
}

export interface Group {
  value: string;
  viewValue: string;
}


export class apidata {
  constructor(private _httpClient: HttpClient) {}

  getapidata(sort: string, order: string, page: number, apitable: string, cropid:number): Observable<apidataApi> {
  
 
   
    const href = 'https://waterlink.ruciak.net/api/apidata.php';
    const requestUrl =
        `${href}?orderby=${sort}&orderdirection=${order}&page=${page}&table=${apitable}&cropid=${cropid}`;
 

    return this._httpClient.get<apidataApi>(requestUrl,{ headers: {'token':localStorage.getItem('token')}});
  }
}

 
