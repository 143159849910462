


<mat-drawer-container class="example-container">
  <mat-drawer mode="side" opened >
    
  

    <button mat-menu-item *ngIf="logoutbtn"  routerLink="dashboard"   [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact:false}"  (click)="$event.stopPropagation();">
      <span class="material-icons">
        analytics
        </span>
        <span class="buttonlabel" [matBadge]="BadgeDashboard" [matBadgeHidden]="BadgeDashboardHidden" matBadgeColor="accent">
          Waterlink
      </span>

    </button>

          
    <button mat-menu-item *ngIf="logoutbtn" routerLink="/reports"  [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact:false}">
      <span class="material-icons">
        description
        </span>
        <span class="buttonlabel">
          Reports
      </span>
    </button>
  
      
    <button mat-menu-item *ngIf="logoutbtn" routerLink="/fields"  [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact:false}">
      <span class="material-icons">
        nature
        </span>
        <span class="buttonlabel" [matBadge]="BadgeCrops" [matBadgeHidden]="BadgeCropsHidden" matBadgeColor="accent">
          Fields
      </span>
    </button>


  
    <button mat-menu-item *ngIf="logoutbtn" routerLink="/farms"  [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact:false}">
      <span class="material-icons">
        calendar_view_month
        </span>
        <span class="buttonlabel" [matBadge]="BadgeFarms" [matBadgeHidden]="BadgeFarmsHidden" matBadgeColor="accent">
          Farms
      </span>
    </button>
  
  


  
    <div id="AdminMenu" *ngIf="admin"  style="display:block;" >
    <button mat-menu-item *ngIf="logoutbtn" routerLink="/users"  [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact:false}">
      <span class="material-icons">
        supervised_user_circle
        </span>
        <span class="buttonlabel">
          Users
      </span>
    </button>
    <button mat-menu-item *ngIf="logoutbtn" routerLink="/apidata"  [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact:false}">
      <span class="material-icons">
        api
        </span>
        <span class="buttonlabel">
          API Data
      </span>
    </button>
  </div>
    <button mat-menu-item *ngIf="logoutbtn" (click)="logout()">
      <span class="material-icons">
        logout
        </span>
        <span class="buttonlabel">
          Logout
      </span>
    </button>
  
   
  
    <button mat-menu-item *ngIf="loginbtn" routerLink="login" routerLinkActive="active">
      
      <span class="material-icons">
        login
        </span>
        <span class="buttonlabel">
          Login
      </span>

    </button>

    
  


  </mat-drawer>
  <mat-drawer-content autosize style=" overflow-y: scroll; margin-left: 155px;"> 
    <router-outlet></router-outlet> 
  </mat-drawer-content>
</mat-drawer-container>

 
 <!--
<button mat-raised-button [matMenuTriggerFor]="menu" *ngIf="logoutbtn" style="float: right;margin-top:5px;">
  <span class="material-icons">
    menu
    </span>
    <span class="buttonlabel" style="top: 0px;">
      Waterlink
  </span>
</button>
-->

<mat-menu #menu="matMenu"  xPosition="before">

 
</mat-menu>





   
 
 