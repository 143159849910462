import {HttpClient} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';

import { Router } from '@angular/router';
import { ApiService } from '../../api.service';

import { first } from 'rxjs/operators';

/**
 * @title Table retrieving data through HTTP
 */
@Component({
  selector: 'app-farms',
  templateUrl: './farms.component.html',
  styleUrls: ['./farms.component.css']
})
export class FarmsComponent implements AfterViewInit {
  displayedColumns: string[] = [ 'name', 'customername', 'address',  'email',  'phone', 'size'];
  Farms: Farms | null;
  data: Farm[] = [];

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private dataService: ApiService,private _httpClient: HttpClient, private router: Router) {


         //Check for Farm
         this.dataService.farmGet('').pipe(first()).subscribe(data => { 
          if (data.total_count == 0){
            this.router.navigate(['/farms/add']);
          }else{
            document.getElementById("container").style.display = "block";
            
          }
        });

    

  }

  ngAfterViewInit() {
    

    this.Farms = new Farms(this._httpClient);

    // If the farm changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = false;
          return this.Farms!.getFarms(
            this.sort.active, this.sort.direction, this.paginator.pageIndex);
        }),
        map(data => {
          if (data["error"] != null){
            window.location.href = '/#/login?returnUrl=%2Fdashboard';
          }
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
     
          this.resultsLength = data.total_count;

          return data.farms;
        }),
        catchError(() => {
          this.isLoadingResults = false;

          window.location.href = '/#/login?returnUrl=%2Fdashboard';
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }
}

export interface FarmsApi {
  farms: Farm[];
  total_count: number;
}

export interface Farm {
  id: string;
  name: string;
  customername: string;
  address: string;
  email: string;
  phone: string;
  size: string;
}

/** An example database that the data source uses to retrieve data for the table. */
export class Farms {
  constructor(private _httpClient: HttpClient) {}

  getFarms(sort: string, order: string, page: number): Observable<FarmsApi> {


    const href = 'https://waterlink.ruciak.net/api/farms.php';
    const requestUrl =
        `${href}?sort=${sort}&order=${order}&page=${page + 1}`;
        
   
    return this._httpClient.get<FarmsApi>(requestUrl,{ headers: {'token':localStorage.getItem('token')}});
  }
}
