import {HttpClient} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';

import { HttpHeaders } from '@angular/common/http';

/**
 * @title Table retrieving data through HTTP
 */
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements AfterViewInit {
  displayedColumns: string[] = [ 'name', 'email', 'active', 'admin', 'lastlogin'];
  Users: Users | null;
  data: User[] = [];

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _httpClient: HttpClient) {}

  ngAfterViewInit() {
    this.Users = new Users(this._httpClient);

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = false;
          return this.Users!.getUsers(
            this.sort.active, this.sort.direction, this.paginator.pageIndex);
        }),
        map(data => {
          if (data["error"] != null){
            window.location.href = '/#/login?returnUrl=%2Fdashboard';
          }
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
     
          this.resultsLength = data.total_count;

          return data.users;
        }),
        catchError(() => {
          this.isLoadingResults = false;

          window.location.href = '/#/login?returnUrl=%2Fdashboard';
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }
}

export interface UsersApi {
  users: User[];
  total_count: number;
}

export interface User {
  id: string;
  name: string;
  email: string;
  active: string;
  admin: string;
}

/** An example database that the data source uses to retrieve data for the table. */
export class Users {
  constructor(private _httpClient: HttpClient) {}

  getUsers(sort: string, order: string, page: number): Observable<UsersApi> {

  
    const href = 'https://waterlink.ruciak.net/api/users.php?';
    const requestUrl =
        `${href}?sort=${sort}&order=${order}&page=${page + 1}`;
        

    return this._httpClient.get<UsersApi>(requestUrl,{ headers: {'token':localStorage.getItem('token')}});
  }
}
