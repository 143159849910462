<div class="container">

  <form [formGroup]="angForm" autocomplete="off" >
    <div class="row" *ngIf="displayNavigation">
      <div class="col-9">
        <h2><button [routerLink]="['/dashboard']"  mat-raised-button >Dashboard</button> <button [routerLink]="['/farms']" mat-raised-button >Farms</button> {{mode}} Farm</h2>
      </div>
    </div>
    <div class="row" *ngIf="displayWelcome">
      <div class="middleform">
        <h2>Waterlink Setup</h2>
        Complete the followings questions about your farm. 
        
      </div>
    </div>



  <mat-tab-group [ngClass]="mode=='Add' ? 'hideifsingle' : ''">
    <mat-tab label="Details">
      <div class="middleform">

          <mat-form-field class="full-width">
            <mat-label>Farm Name</mat-label>
            <input matInput  formControlName="name"  placeholder="Farm Name">
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Contact Name</mat-label>
            <input matInput   formControlName="customername"  placeholder="Customer Name">
          </mat-form-field>
    
          <mat-form-field class="full-width">
            <mat-label>Address</mat-label>
            <input matInput  formControlName="address"  placeholder="Address">
          </mat-form-field>
 
          <mat-form-field class="full-width">
            <mat-label>Email</mat-label>
            <input matInput  formControlName="email"  placeholder="Email">
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Phone</mat-label>
            <input matInput  formControlName="phone"  placeholder="Phone">
          </mat-form-field>

          <mat-form-field class="full-width">
            <mat-label>Size (hectares)</mat-label>
            <input matInput  formControlName="size"  placeholder="Size">
          </mat-form-field>
 </div>
        </mat-tab>
          
          <mat-tab label="Options"  *ngIf="mode!='Add'">
            <div class="middleform">
            <button mat-raised-button color="outline"  (click)="saveform(true)">Copy Farm</button>
            <br><br><button mat-raised-button color="outline" (click)="deleteform()">Delete Farm</button>
            <br><br>
          </div>
          </mat-tab>

        </mat-tab-group>
 
        <div class="middleform">
        <div class="col-12 pr-5">
          <button mat-raised-button color="primary" (click)="saveform(false)" *ngIf="displayNavigation">Save</button>
          <button mat-raised-button color="primary" (click)="saveform(false)" *ngIf="displayWelcome">Next</button>
          
          <button mat-button [routerLink]="['/farms']" type="button"  *ngIf="displayNavigation" >Cancel</button>
        </div>
      </div>
    </form>
    </div>

