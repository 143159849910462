import {HttpClient} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, first, map, startWith, switchMap} from 'rxjs/operators';
import { Router } from '@angular/router';
 
import { ApiService } from 'src/app/api.service';

/**
 * @title Table retrieving data through HTTP
 */
@Component({
  selector: 'app-crops',
  templateUrl: './crops.component.html',
  styleUrls: ['./crops.component.css']
})
export class CropsComponent implements AfterViewInit {
 
 
  displayedColumns: string[] = [ 'name','farmname', 'crop', 'cropvariety', 'plantingdate'];
  displayedColumnsFilter: string[] = [ 'blank','farmnameFilter', 'blank', 'blank', 'blank'];

  Crops: Crops | null;
  data: Crop[] = [];
  dataBackup: Crop[] = [];

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
 
  farmsFilter = [];
  filterFarmName = '';

   constructor(private _httpClient: HttpClient,private dataService: ApiService, private router: Router) {
         //Check for Farm
         this.dataService.farmGet('').pipe(first()).subscribe(data => { 
          if (data.total_count == 0){
            this.router.navigate(['/farms/add']);
          }else{
            //Check for Field
            this.dataService.cropGet('').pipe(first()).subscribe(data => { 
              if (data.total_count == 0){
                this.router.navigate(['/fields/add']);
              }else{
                document.getElementById("container").style.display = "block";
              }
            });
          }
        });




   }

  ngAfterViewInit() {
    this.Crops = new Crops(this._httpClient);

    // If the crop changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = false;
          return this.Crops!.getCrops(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.filterFarmName);
        }),
        map(data => {
          if (data["error"] != null){
            window.location.href = '/#/login?returnUrl=%2Fdashboard';
          }
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
     
          this.resultsLength = data.total_count;

          return data.crops;
        }),
        catchError(() => {
          this.isLoadingResults = false;

          window.location.href = '/#/login?returnUrl=%2Fdashboard';
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data = data
        this.dataBackup = data

   
        var l = data.length, i;

        for( i=0; i<l; i++) {
   
          if(!this.farmsFilter.includes(data[i].farmname)){
              this.farmsFilter.push(data[i].farmname);
          }
        }


 
      });
  }
  ngOnInit() {



  //  this.dataService.farmGet('')
  //  .pipe(first())
  //  .subscribe(
  //  data => {
  //    this.farms = data.farms;
  //  });
    

  }
  farmChangeValue(value:any){
  if (value != undefined){
    this.filterFarmName = value;
  }else{
    this.filterFarmName ='';
  }

  this.ngAfterViewInit()

 
  }
}

export interface CropsApi {
  crops: Crop[];
  total_count: number; 
}

export interface Crop {
  farmname: any;
  id: string;
  name: string;
  crop: string;
  cropvariety: string;
  plantingdate: string;
}


/** An example database that the data source uses to retrieve data for the table. */
export class Crops {
  constructor(private _httpClient: HttpClient) {}

  getCrops(sort: string, order: string, page: number, filterFarmName: string): Observable<CropsApi> {

  
    const href = 'https://waterlink.ruciak.net/api/crops.php?';
    const requestUrl =
        `${href}?sort=${sort}&order=${order}&page=${page + 1}&FARMNAME=${filterFarmName}`;
        

    return this._httpClient.get<CropsApi>(requestUrl,{ headers: {'token':localStorage.getItem('token')}});
  }
}
