
<div class="container"  *ngIf="displayDashboard"  id="MainCard"  style="display:none;">


  <mat-card class="cardfull">
    <mat-card-header>
      <div mat-card-avatar class="avatar">

        <span class=" material-icons largeicon">
          analytics
            </span>

      </div>
      <span class=" Heading HeadingMain" style="padding-left: 0px;padding-top: 3px;">Waterlink Dashboard</span>

    </mat-card-header>
  </mat-card>

 
  <div *ngIf="displayDashboard" >
  <mat-card class="cardfull" id="AlertCard"  style="display:none; background: #ffe8e8;">
    <mat-card-header >

      <span class="red material-icons largeicon" style="padding-right: 15px;">
        notifications
          </span>
            <div class="mat-elevation-z8"  style="width: 100%;">
              <div class="example-loading-shade"
                   *ngIf="isLoadingResults || isRateLimitReached">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
           
              </div>
            
              <div class="the-table-container">
            
                <mat-table #table [dataSource]="data" class="example-table"
                matSort matSortActive="id"  >


                
                <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
                  
         
                  <mat-header-cell  mat-sort-header *matHeaderCellDef ><div *ngIf="disCol!='deleteaction'">{{ disCol | titlecase }}</div></mat-header-cell>
        
                  <mat-cell  *matCellDef="let element "> 
                    {{element[disCol]}}
                  <div  *ngIf="disCol=='deleteaction'" style="width: 100%;text-align: right; cursor: pointer;"><i style="cursor: pointer;"class="material-icons" (click)="delete(element)">
                    delete
                    </i></div>
                  </mat-cell>
      
       
              </ng-container>

 
       
              


                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>

          
              </div>
            
              <mat-paginator  [length]="resultsLength" [pageSize]="3"></mat-paginator>
            </div>


        




    </mat-card-header>

  </mat-card>


  <mat-card class="cardfull">
    <mat-card-header>
      <div mat-card-avatar class="avatar">

        <span class=" material-icons largeicon">
          nature
            </span>

      </div>
  
      <mat-select [(value)]="crops[SelectedFielder].id" id="SelectedField" (selectionChange)="changeValue($event.value)" class="pt-2 largeSelect">
        <mat-option *ngFor="let crop of crops" [value]="crop.id">{{crop.farmname}} - {{crop.name}}</mat-option>
      </mat-select>
 

      <button mat-raised-button color="secondary"  (click)="goToReports()" style="width:200px; margin:10px;margin-left:30px;">Reports</button>
      <button mat-raised-button color="secondary"  (click)="goToField()" style="width:200px; margin:10px;margin-left:15px;">Update Field</button>
        <table style="width:600px;height:60px;">
          <tr *ngIf="displayIrrigationCosts">
            <td NOWRAP><h3 style="padding-left: 20px;margin-bottom: 0px;">Last Irrigation Cost</h3></td>
            <td NOWRAP valign="right"><h3 style="margin-bottom: 0px;margin-right: 10px;float:right;" *ngFor="let farm of dashboardDataSource">&nbsp;&nbsp;&nbsp;{{farm.lastirrigationcost}} </h3></td>
          </tr>
          <tr *ngIf="displayIrrigationCosts">
            <td NOWRAP><h3 style="padding-left: 20px;margin-bottom: 0px;">Irrigation Costs to Date</h3></td>
            <td NOWRAP valign="right"><h3 style="margin-bottom: 0px;margin-right: 10px;float:right;" *ngFor="let farm of dashboardDataSource">&nbsp;&nbsp;&nbsp;{{farm.irrigationcoststodate}} </h3></td>
          </tr>
        </table>


    </mat-card-header>
  <mat-card-content style="padding-top:20px">
    <table mat-table [dataSource]="dashboardDataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
    
     
      <ng-container matColumnDef="fieldarea">
        <th mat-header-cell *matHeaderCellDef class="percent15"> Field Size ha </th>
        <td mat-cell *matCellDef="let element"> {{element.fieldarea}} </td>
      </ng-container>

      <ng-container matColumnDef="typeofirrigation">
        <th mat-header-cell *matHeaderCellDef class="percent15"> Irrigation System </th>
        <td mat-cell *matCellDef="let element"> {{element.typeofirrigation}} </td>
      </ng-container>
    

      <ng-container matColumnDef="crop">
        <th mat-header-cell *matHeaderCellDef class="percent15"> Crop </th>
        <td mat-cell *matCellDef="let element"> {{element.crop}} </td>
      </ng-container>

      <ng-container matColumnDef="cropvariety">
        <th mat-header-cell *matHeaderCellDef class="percent15"> Variety </th>
        <td mat-cell *matCellDef="let element"> {{element.cropvariety}} </td>
      </ng-container>
      <ng-container matColumnDef="Age">
        <th mat-header-cell *matHeaderCellDef class="percent15"> Age </th>
        <td mat-cell *matCellDef="let element"> {{element.Age}} </td>
      </ng-container>
      <ng-container matColumnDef="targetcrop">
        <th mat-header-cell *matHeaderCellDef class="percent15"> Target </th>
        <td mat-cell *matCellDef="let element"> {{element.targetcrop}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsCrop"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsCrop;"></tr>
    </table>


  </mat-card-content>

  <mat-card-header style="padding-top:10px">

    <span class=" material-icons largeicon">
      opacity
        </span>
        <h2 class="Heading HeadingSub">Water Agronomics</h2>


    




</mat-card-header>
<mat-card-content  style="padding-top:10px">

 

    <table mat-table [dataSource]="dashboardDataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
    
     
      <ng-container matColumnDef="TotalETomm">
        <th mat-header-cell *matHeaderCellDef class="percent6">  ETo mm </th>
        <td mat-cell *matCellDef="let element"> {{element.TotalETomm}} </td>
      </ng-container>
      <ng-container matColumnDef="TotalRainmm">
        <th mat-header-cell *matHeaderCellDef class="percent6">  Rain mm </th>
        <td mat-cell *matCellDef="let element"> {{element.TotalRainmm}} </td>
      </ng-container>
      <ng-container matColumnDef="TotalIrrigationEvents">
        <th mat-header-cell *matHeaderCellDef class="percent6">  Irrigation Events</th>
        <td mat-cell *matCellDef="let element"> {{element.TotalIrrigationEvents}} </td>
      </ng-container>
      <ng-container matColumnDef="TotalIrrigationRainfallmm">
        <th mat-header-cell *matHeaderCellDef class="percent6">  Irrigation & Rainfall mm</th>
        <td mat-cell *matCellDef="let element"> {{element.TotalIrrigationRainfallmm}} </td>
      </ng-container>
      <ng-container matColumnDef="TotalIrrigationinCropCyclemm">
        <th mat-header-cell *matHeaderCellDef class="percent6">  Irrigation in Crop Cycle mm</th>
        <td mat-cell *matCellDef="let element"> {{element.TotalIrrigationinCropCyclemm}} </td>
      </ng-container>
      <ng-container matColumnDef="TotalWaterpumpedMLFieldha">
        <th mat-header-cell *matHeaderCellDef class="percent6">  Water Pumped ML/Field ha</th>
        <td mat-cell *matCellDef="let element"> {{element.TotalWaterpumpedMLFieldha}} </td>
      </ng-container>
      <ng-container matColumnDef="TotalWaterPumpedMLha">
        <th mat-header-cell *matHeaderCellDef class="percent6"> Water Pumped ML/ha</th>
        <td mat-cell *matCellDef="let element"> {{element.TotalWaterPumpedMLha}} </td>
      </ng-container>  
      <!--- 
      <ng-container matColumnDef="TotalWaterpumpedmmm2">
        <th mat-header-cell *matHeaderCellDef class="percent6"> Water pumped mm/m2</th>
        <td mat-cell *matCellDef="let element"> {{element.TotalWaterpumpedmmm2}} </td>
      </ng-container>   -->
               
      <tr mat-header-row *matHeaderRowDef="displayedColumnsWater1"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsWater1;"></tr>
    </table>


    <table mat-table [dataSource]="dashboardDataSource" class="mt-1 mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
    
    
      <ng-container matColumnDef="WaterAppliedPreWettingmm">
        <th mat-header-cell *matHeaderCellDef class="percent6">Water Applied Pre-Wetting mm</th>
        <td mat-cell *matCellDef="let element"> {{element.WaterAppliedPreWettingmm}} </td>
      </ng-container>    
      <ng-container matColumnDef="TotalWaterreceivedinSoilmm">
        <th mat-header-cell *matHeaderCellDef class="percent6"> Water received in Soil mm</th>
        <td mat-cell *matCellDef="let element"> {{element.TotalWaterreceivedinSoilmm}} </td>
      </ng-container>      
      <ng-container matColumnDef="TotalCropWaterUsedmm">
        <th mat-header-cell *matHeaderCellDef class="percent6"> Crop Water Used mm</th>
        <td mat-cell *matCellDef="let element"> {{element.TotalCropWaterUsedmm}} </td>
      </ng-container>   
      <ng-container matColumnDef="TotalDrainageWatermm">
        <th mat-header-cell *matHeaderCellDef class="percent6"> Drainage Water mm</th>
        <td mat-cell *matCellDef="let element"> {{element.TotalDrainageWatermm}} </td>
      </ng-container>      
      <ng-container matColumnDef="Totalcropstressdays">
        <th mat-header-cell *matHeaderCellDef class="percent6"> Crop Stress Days</th>
        <td mat-cell *matCellDef="let element"> {{element.Totalcropstressdays}} </td>
      </ng-container>   
      <ng-container matColumnDef="Yieldtha">
        <th mat-header-cell *matHeaderCellDef class="percent6">Yield t/ha</th>
        <td mat-cell *matCellDef="let element"> {{element.Yieldtha}} </td>
      </ng-container>     
      <ng-container matColumnDef="WaterProductivityDmm">
        <th mat-header-cell *matHeaderCellDef class="percent6">Water Productivity $/mm</th>
        <td mat-cell *matCellDef="let element"> {{element.WaterProductivityDmm}} </td>
      </ng-container>                                    
      <ng-container matColumnDef="WaterProductivityTmm">
        <th mat-header-cell *matHeaderCellDef class="percent6">Water Productivity t/mm</th>
        <td mat-cell *matCellDef="let element"> {{element.WaterProductivityTmm}} </td>
      </ng-container>           
      
      <tr mat-header-row *matHeaderRowDef="displayedColumnsWater2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsWater2;"></tr>
    </table>

  </mat-card-content>
  <mat-card-header style="padding-top:10px">

    <span class=" material-icons largeicon">
      account_balance
        </span>
        <h2 class="Heading HeadingSub">Financials</h2>


    




</mat-card-header>
<mat-card-content  style="padding-top:10px">
 
    
        <table mat-table [dataSource]="dashboardDataSource" class="mat-elevation-z8">
    
          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
                
        
                
         
          <ng-container matColumnDef="FixedCostsTotal">
            <th mat-header-cell *matHeaderCellDef class="percent9">Fixed Costs  $</th>
            <td mat-cell *matCellDef="let element"> {{element.FixedCostsTotal}} </td>
          </ng-container>
          <ng-container matColumnDef="VariableCostsTotal">
            <th mat-header-cell *matHeaderCellDef class="percent9">Variable Costs  $</th>
            <td mat-cell *matCellDef="let element"> {{element.VariableCostsTotal}} </td>
          </ng-container>
          <ng-container matColumnDef="HiddenCostsTotal">
            <th mat-header-cell *matHeaderCellDef class="percent9">Hidden Costs  $</th>
            <td mat-cell *matCellDef="let element"> {{element.HiddenCostsTotal}} </td>
          </ng-container>
          <ng-container matColumnDef="TotalCosts">
            <th mat-header-cell *matHeaderCellDef class="percent9"> Costs $</th>
            <td mat-cell *matCellDef="let element"> {{element.TotalCosts}} </td>
          </ng-container>
          <ng-container matColumnDef="TotalYield">
            <th mat-header-cell *matHeaderCellDef class="percent9"> Yield $</th>
            <td mat-cell *matCellDef="let element"> {{element.TotalYield}} </td>
          </ng-container>
          <ng-container matColumnDef="Priceperton">
            <th mat-header-cell *matHeaderCellDef class="percent9">Price per ton $</th>
            <td mat-cell *matCellDef="let element"> {{element.Priceperton}} </td>
          </ng-container>
          <ng-container matColumnDef="TotalWaterCosts">
            <th mat-header-cell *matHeaderCellDef class="percent9"> Water Costs $</th>
            <td mat-cell *matCellDef="let element"> {{element.TotalWaterCosts}} </td>
          </ng-container>  
          <ng-container matColumnDef="TotalElectricityCharge">
            <th mat-header-cell *matHeaderCellDef class="percent9"> Electricity Charge $</th>
            <td mat-cell *matCellDef="let element"> {{element.TotalElectricityCharge}} </td>
          </ng-container>   


          <ng-container matColumnDef="TotalCostperIrrigationEvent">
            <th mat-header-cell *matHeaderCellDef class="percent9"> Cost per Irrigation Event $</th>
            <td mat-cell *matCellDef="let element"> {{element.TotalCostperIrrigationEvent}} </td>
          </ng-container>    
          <ng-container matColumnDef="Revenue">
            <th mat-header-cell *matHeaderCellDef class="percent9">Revenue $</th>
            <td mat-cell *matCellDef="let element"> {{element.Revenue}} </td>
          </ng-container>      
          <ng-container matColumnDef="RevenuelessExpenses">
            <th mat-header-cell *matHeaderCellDef class="percent9">Revenue less Expenses $</th>
            <td mat-cell *matCellDef="let element"> {{element.RevenuelessExpenses}} </td>
          </ng-container>   
   
 
          
          <tr mat-header-row *matHeaderRowDef="displayedColumnsFin"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsFin;"></tr>
        </table>
    
    
      </mat-card-content>
  </mat-card>
</div>

<br><br>

</div>