<div class="container">
  <div class="row">
    <div class="col-9">
      <h2><button [routerLink]="['/dashboard']"  mat-raised-button >Dashboard</button> Seasons</h2>
    </div>
    <div class="col-3 text-right">
      <button  [routerLink]="['/seasoneditor']" mat-raised-button color="primary">Add Season</button>
    </div>
  </div>
<div class="example-container mat-elevation-z8">
    <div class="example-loading-shade"
         *ngIf="isLoadingResults || isRateLimitReached">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
 
    </div>
  
    <div class="the-table-container">
  
      <table mat-table [dataSource]="data" class="example-table"
             matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
 
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row">{{row.number}}</td>
        </ng-container>
  
 
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>id</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>
  
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row">
            <a [title]="row.name + ' details'" [routerLink]="['/seasoneditor', row.id]">
              {{ row.name }}
            </a>
             </td>
        </ng-container>
 
        <ng-container matColumnDef="farmname">
          <th mat-header-cell *matHeaderCellDef>Farm</th>
          <td mat-cell *matCellDef="let row">
            {{ row.farmname }}
             </td>
        </ng-container>
   
 




        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  
    <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
  </div>
</div>
