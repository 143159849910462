import { Component } from '@angular/core';
import { ApiService } from './api.service';
import {MatBadgeModule} from '@angular/material/badge';
import{ BadgeService } from './badge.service';

@Component({
selector: 'app-root',
templateUrl: './app.component.html',
styleUrls: ['./app.component.css']
})

export class AppComponent {
loginbtn:boolean;
isAdmin:boolean;
logoutbtn:boolean;
BadgeDashboard:string = "";
BadgeDashboardHidden:boolean = true;
BadgeCrops:string = "";
BadgeCropsHidden:boolean = true;
BadgeFarms:string = "";
BadgeFarmsHidden:boolean = true;
  title: 'Waterlink';

  admin: boolean = false;

constructor(private dataService: ApiService, public BadgeService: BadgeService) {


  this.updateAdmin();
  BadgeService.updateAdmin.subscribe(name => this.updateAdmin());
  
  this.updateBadges();
  BadgeService.updateBadges.subscribe(name => this.updateBadges());
 

  dataService.getLoggedInName.subscribe(name => this.changeName(name));

  if(this.dataService.isLoggedIn())
  {
    console.log("loggedin");
    this.loginbtn=false;
    this.logoutbtn=true
  }else{
    this.loginbtn=true;
    this.logoutbtn=false
  }

 

}

private updateAdmin(){


  if (localStorage.getItem('admin') == "1"){
    this.admin = true
  }else{
    this.admin = false
  } 
}

private updateBadges(){


  this.BadgeDashboard = this.BadgeService.BadgeDashboard();
  this.BadgeDashboardHidden = this.BadgeService.BadgeDashboardHidden();
  this.BadgeCrops = this.BadgeService.BadgeCrops();
  this.BadgeCropsHidden = this.BadgeService.BadgeCropsHidden();
  this.BadgeFarms = this.BadgeService.BadgeFarms();
  this.BadgeFarmsHidden = this.BadgeService.BadgeFarmsHidden();  
}

private changeName(name: boolean): void {
  this.logoutbtn = name;
  this.loginbtn = !name;
}

logout()
{
  this.dataService.deleteToken();
  window.location.reload();
}

 
}