
<div class="container"  id="container" style="display:none;">



 
<mat-card class="cardfull">
  <mat-card-header>
    <div mat-card-avatar class="avatar">

      <span class=" material-icons largeicon">
        description
          </span>

    </div>
    <span class=" Heading HeadingMain" style="padding-left: 0px;padding-top: 3px;">Reports</span>

    




  </mat-card-header>
</mat-card>
<mat-card class="cardfull">
  <mat-form-field class="full-width" appearance="standard" style="width:32%">    

  <mat-select [(value)]="SelectedCrop" id="SelectedCrop" (selectionChange)="changeValueCrop($event)">
    <mat-option *ngFor="let crop of crops" [value]="crop.id" api_key="crop.api_key">{{crop.farmname}} - {{crop.name}}</mat-option>
  </mat-select>

</mat-form-field>
  <mat-form-field class="full-width" appearance="standard" style="width:32%">       
    <mat-select [(value)]="selectedGroup" (valueChange)="changeValueGroup($event)" >

      <mat-option *ngFor="let group of groups" [value]="group.value">
        {{group.viewValue}} 
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width" appearance="standard" *ngIf="displaySubReports"  style="width:32%">       
  <mat-select [(value)]="selected" (valueChange)="changeValue($event)">

    <mat-option *ngFor="let food of foods" [value]="food.value">
      {{food.viewValue}} 
    </mat-option>
  </mat-select>
</mat-form-field>


<div>
<mat-tab-group   [(selectedIndex)]="DataTabIndex"  [ngClass]="!displaySubReports ? 'hideifsingle' : ''">
  <mat-tab>
    <ng-template mat-tab-label>
      <span class="material-icons">
        storage
        </span>&nbsp;
        Data
    </ng-template>
        <div class="container containerWide">
          <div class="example-container mat-elevation-z8">
              <div class="example-loading-shade"
                   *ngIf="isLoadingResults || isRateLimitReached">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
           
              </div>
              <div class="example-loading-shade" *ngIf="isNoData" style="    font-size: large;
              padding-left: 35px;"
                   >
                <br>No data found
           
              </div>            
              <div class="the-table-container">
            
                <mat-table #table [dataSource]="data" class="example-table" matSort matSortActive="id" matSortDisableClear matSortDirection="desc">
          
                <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
          
                  <mat-header-cell  mat-sort-header *matHeaderCellDef >{{ disCol | titlecase }}</mat-header-cell>
                  <mat-cell *matCellDef="let element "> {{element[disCol]}} 
                  </mat-cell>
                
              </ng-container>
          
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
          
          
              </div>
            
              <mat-paginator  id="paginator" style="display:block;"  [length]="resultsLength" [pageSize]="7"></mat-paginator>
            </div>
        </div>
        <br><br><br>
      </mat-tab>
  <mat-tab  *ngIf="displaySubReports">
    <ng-template mat-tab-label>
      <span class="material-icons">
        leaderboard
        </span>&nbsp;
      Graph
    </ng-template>

<div class="divGraph container containerWide" >
  <div class="divGraphBorder">
  <iframe scrolling="no" [src]="graphurl | safe" class="graph" id="graph" *ngIf="graphLoaded"></iframe>
</div>
</div>

</mat-tab>

</mat-tab-group>

</div>
</mat-card>

</div>
