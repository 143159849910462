<div class="container">


  <div class="row" *ngIf="displayNavigation">
    <div class="col-9">
      <h2><button [routerLink]="['/dashboard']"  mat-raised-button >Dashboard</button> <button [routerLink]="['/fields']" mat-raised-button >Fields</button>
        {{mode}} Field
        </h2>
      
    </div>

  </div>
 

  <div class="row" *ngIf="displayWelcome">
    <div class="middleform">
      <h2>Waterlink Setup</h2><br>
      Complete the Required Information section below to connect your field to Waterlink. Other questions and finanical information can be completed now or at a later time.
      <br><br>
      Please allow up to 10 minutes for probe data to download to Waterlink. 
      
    </div>
  </div>


  <form [formGroup]="angForm"  autocomplete="off" >

      <div class="jumbotron_not" [ngClass]="displayWelcome ? 'middleform' : ''" class="pt-2">
  

    


          <mat-tab-group  (selectedTabChange)="calCosts()">
  


 
   
            <!--

                [ngClass]="mode=='Add' ? 'hideifsingle' : ''"
            <mat-tab label="Scenario Planning">

              <mat-tab-group (selectedTabChange)="calCosts()">
                <mat-tab label="Harvest extension - potatoes">
 
         
<br><br>
              

EXTRA GROWTH ___ WEEKS<br>
<br>
EXTRA IRRIGATIONS REQUIRED ____<br>
(default 1 irrigation per day software
calculates on number of weeks
entered above but allow for grower
override)<br>
<br>
EXTRA IRRIGATION COST $______<br>
(default software calculates $1000
per irrigation* #2 “extra irrigations
required” but allow for grower
override)<br>
<br>
Rate per tonne at larger size with
____ (from #1) weeks extra
growth =________ <br>(enter $300
average /week + 20% [grower can
override]<br>
<br>
Extra yield possible = $XXX<br>
<br>
Extra irrigation approximate = $XXXX
<br>
Cost/benefit = -/+$XXX (red minus or
black positive)<br>
</mat-tab>

<mat-tab label="Harvest forward prediction">


TO BE DEVELOPED
</mat-tab>
</mat-tab-group>
  


            </mat-tab>-->
            
            <mat-tab label="Field Information">
 
              

              <div [ngClass]="!displayWelcome ? 'middleform' : ''">
                <div  [ngClass]="displayWelcome ? 'welcomeborder' : ''">
   
                  <h3 *ngIf="displayWelcome">Required Information</h3>

          <mat-form-field  class="full-width" appearance="standard">
            <mat-label>Field Name</mat-label>
          
            <input matInput formControlName="name" placeholder="name">
          </mat-form-field>
       


          <mat-form-field  class="full-width" appearance="standard">
            <mat-label>Farm</mat-label>
          
            <mat-select matNativeControl formControlName="farmid">
              <mat-option *ngFor="let farm of farms" [value]="farm.id">{{farm.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field  class="full-width"  appearance="standard">
            <mat-label>Planting Date</mat-label>
            <input matInput [matDatepicker]="pickerplanting" formControlName="planting">
            <mat-datepicker-toggle matSuffix [for]="pickerplanting"></mat-datepicker-toggle>
            <mat-datepicker #pickerplanting></mat-datepicker>
          </mat-form-field>
          
          <mat-form-field  class="full-width" appearance="standard">
            <mat-label>Harvest Date</mat-label>
            <input matInput [matDatepicker]="pickerharvest" formControlName="harvest">
            <mat-datepicker-toggle matSuffix [for]="pickerharvest"></mat-datepicker-toggle>
            <mat-datepicker #pickerharvest></mat-datepicker>
          </mat-form-field>  


  

          <mat-form-field class="full-width" appearance="standard"><mat-label>API Key</mat-label><input matInput  formControlName="api_key"  placeholder="API Key"></mat-form-field>
          <mat-form-field class="full-width" appearance="standard"><mat-label>API Logger</mat-label><input matInput  formControlName="api_logger"  placeholder="API Logger"></mat-form-field>
          <mat-form-field class="full-width" appearance="standard" ><mat-label>API User ID</mat-label><input matInput  formControlName="api_userid"  placeholder="API User ID"></mat-form-field>
          <mat-form-field class="full-width"  appearance="standard"><mat-label>Field Area (ha)</mat-label><input matInput type="number" formControlName="fieldarea"  placeholder="Field Area"></mat-form-field>
          
   
          <div class="col-12 pt-4 pb-3  pr-5"  *ngIf="displayWelcome">
        
            <button mat-raised-button color="primary"  (click)="saveform(false)">Save & download data to Waterlink</button> 

          </div>


          </div>
          <br><br>
          <h3>Planting</h3>
          <mat-form-field class="full-width" appearance="standard"><mat-label>Crop</mat-label><input matInput  formControlName="crop"  placeholder="Crop"></mat-form-field>
          <mat-form-field class="full-width" appearance="standard"><mat-label>Crop Variety</mat-label><input matInput  formControlName="cropvariety"  placeholder="Crop Variety"></mat-form-field>
          <mat-form-field class="full-width" appearance="standard"><mat-label>Seed Rate (t/ha)</mat-label><input matInput type="number" formControlName="seedrate"  placeholder="Seed Rate"></mat-form-field>
          <mat-form-field class="full-width" appearance="standard"><mat-label>Target Market</mat-label><input matInput  formControlName="targetcrop"  placeholder="Target Market"></mat-form-field>
          <mat-form-field class="full-width" appearance="standard"><mat-label>Plant Spacing Across Row (cm)</mat-label><input matInput type="number" formControlName="plantspacingacross"  placeholder="Plant Spacing across Row"></mat-form-field>
          <mat-form-field class="full-width" appearance="standard"><mat-label>Plant Spacing Down Row (cm)</mat-label><input matInput type="number" formControlName="plantspacingdown"  placeholder="Plant Spacing Down Row"></mat-form-field>
          <mat-form-field class="full-width" appearance="standard"><mat-label>Soil Type 0 - 30 cm</mat-label><input matInput  formControlName="soiltype0_30cm"  placeholder="Soil Type 0 - 30 cm"></mat-form-field>
          <mat-form-field class="full-width" appearance="standard"><mat-label>Soil Type 30 - 60 cm</mat-label><input matInput  formControlName="soiltype30_60cm"  placeholder="Soil Type 30 - 60 cm"></mat-form-field>
          <mat-form-field class="full-width" appearance="standard"><mat-label>Pre-Wetting (mm)</mat-label><input matInput type="number" formControlName="prewetting"  placeholder="Pre-Wetting (mm)"></mat-form-field>
          <br><br>
          <h3>Crop Growth Stages </h3>




              <mat-form-field  class="full-width" appearance="standard">
                <mat-label>Emergence 50% Date</mat-label>
                <input matInput [matDatepicker]="pickeremergence50" formControlName="emergence50">
                <mat-datepicker-toggle matSuffix [for]="pickeremergence50"></mat-datepicker-toggle>
                <mat-datepicker #pickeremergence50></mat-datepicker>
              </mat-form-field>
              <mat-form-field  class="full-width" appearance="standard">
                <mat-label>Emergence 100% Date</mat-label>
                <input matInput [matDatepicker]="pickeremergence100" formControlName="emergence100">
                <mat-datepicker-toggle matSuffix [for]="pickeremergence100"></mat-datepicker-toggle>
                <mat-datepicker #pickeremergence100></mat-datepicker>
              </mat-form-field>
              <mat-form-field  class="full-width" appearance="standard">
                <mat-label>Initiation Date</mat-label>
                <input matInput [matDatepicker]="pickerinitiation" formControlName="initiation">
                <mat-datepicker-toggle matSuffix [for]="pickerinitiation"></mat-datepicker-toggle>
                <mat-datepicker #pickerinitiation></mat-datepicker>
              </mat-form-field>              
              <mat-form-field  class="full-width" appearance="standard">
                <mat-label>Bulking Date</mat-label>
                <input matInput [matDatepicker]="pickerbulkingdate" formControlName="bulkingdate">
                <mat-datepicker-toggle matSuffix [for]="pickerbulkingdate"></mat-datepicker-toggle>
                <mat-datepicker #pickerbulkingdate></mat-datepicker>
              </mat-form-field> 
              <mat-form-field  class="full-width" appearance="standard">
                <mat-label>Canopy Closure 100%</mat-label>
                <input matInput [matDatepicker]="pickercanopyclosure" formControlName="canopyclosure">
                <mat-datepicker-toggle matSuffix [for]="pickercanopyclosure"></mat-datepicker-toggle>
                <mat-datepicker #pickercanopyclosure></mat-datepicker>
              </mat-form-field>               
              <mat-form-field  class="full-width" appearance="standard">
                <mat-label>Senescence Date</mat-label>
                <input matInput [matDatepicker]="pickersenescence" formControlName="senescence">
                <mat-datepicker-toggle matSuffix [for]="pickersenescence"></mat-datepicker-toggle>
                <mat-datepicker #pickersenescence></mat-datepicker>
              </mat-form-field>  

              <mat-form-field  class="full-width" appearance="standard">
                <mat-label>Crop Age at Harvest</mat-label>
                <input matInput [matDatepicker]="pickercropageatharvest" formControlName="cropageatharvest">
                <mat-datepicker-toggle matSuffix [for]="pickercropageatharvest"></mat-datepicker-toggle>
                <mat-datepicker #pickercropageatharvest></mat-datepicker>
              </mat-form-field>   

              <br><br>
            <h3>Irrigation System Information </h3><br>

              <mat-form-field class="full-width" appearance="standard"><mat-label>Type of Irrigation</mat-label><input matInput  formControlName="typeofirrigation"  placeholder="Type of Irrigation"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Pivot Spans (spans)</mat-label><input matInput  type="number" formControlName="pivotspans"  placeholder="Pivot Spans"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Max Flow Rate (gpm)</mat-label><input matInput  type="number" formControlName="maxflowrate"  placeholder="Max Flow Rate"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Pivot Pressure (PSI)</mat-label><input matInput  type="number" formControlName="pivotpressure"  placeholder="Pivot Pressure"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Application Rate at 100% Speed (")</mat-label><input matInput  type="number" formControlName="applicationrateatspeed"  placeholder="Application Rate at 100% Speed"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Pivot Length (ft)</mat-label><input matInput type="number"  formControlName="pivotlength"  placeholder="Pivot Length"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Nozzle Type (K Black)</mat-label><input matInput type="number" formControlName="nozzletype"  placeholder="Nozzle Type"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Distribution Uniformity</mat-label><input matInput  formControlName="distributionuniformity"  placeholder="Distribution Uniformity"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>100% Speed Duration (h)</mat-label><input matInput type="number" formControlName="speedduration"  placeholder="100% Speed Duration"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Application Rate (L/s)</mat-label><input matInput type="number" formControlName="applicationrate"  placeholder="Application Rate "></mat-form-field>
              <br><br>
              <h3>Probe Information</h3>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Site ID</mat-label><input required="false" matInput formControlName="siteid" placeholder="Site ID"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Logger ID</mat-label><input matInput  formControlName="loggerid"  placeholder="Logger ID"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Probe Type</mat-label><input matInput  formControlName="probetype"  placeholder="Probe Type"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Probe Length (cm)</mat-label><input matInput type="number" formControlName="probelength"  placeholder="Probe Length"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Coordinates for Probe LAT</mat-label><input matInput type="number" formControlName="coordinatesforprobelat"  placeholder="Coordinates for Probe LAT"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Coordinates for Probe LONG</mat-label><input matInput type="number" formControlName="coordinatesforprobelong"  placeholder="Coordinates for Probe LONG"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Probe to Plant Distance</mat-label><input matInput  formControlName="probetoplantdistance"  placeholder="Probe to Plant Distance"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Probe to Emitter Distance</mat-label><input matInput  formControlName="probetoemitterdistance"  placeholder="Probe to Emitter Distance"></mat-form-field>

              <mat-form-field  class="full-width"  appearance="standard">
                <mat-label>Probe Install Date</mat-label>
                <input matInput [matDatepicker]="pickerprobeinstalldate" formControlName="probeinstalldate">
                <mat-datepicker-toggle matSuffix [for]="pickerprobeinstalldate"></mat-datepicker-toggle>
                <mat-datepicker #pickerprobeinstalldate></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Data Recording Period (days)</mat-label><input matInput type="number" formControlName="datarecordingperiod"  placeholder="Data Recording Period"></mat-form-field>
              <mat-form-field class="full-width" appearance="standard"><mat-label>Install Method</mat-label><input matInput  formControlName="installmethod"  placeholder="Install Method"></mat-form-field>
              
              <mat-form-field  class="full-width"  appearance="standard">
                <mat-label>De-Installation Date</mat-label>
                <input matInput [matDatepicker]="pickerdeinstallationdate" formControlName="deinstallationdate">
                <mat-datepicker-toggle matSuffix [for]="pickerdeinstallationdate"></mat-datepicker-toggle>
                <mat-datepicker #pickerdeinstallationdate></mat-datepicker>
              </mat-form-field>
   
              <div class="col-12  pt-5  pb-5  pr-5">
      
                <button mat-raised-button color="primary"  (click)="saveform(false)">Save</button>   <button mat-button [routerLink]="['/fields']" type="button" >Cancel</button>
              </div>
            </div>
            </mat-tab>
            <mat-tab label="Financials" >

              <div  [ngClass]="!displayWelcome ? 'middleform' : ''">
              
              <h2>Water Related Direct Costs</h2>
              <button mat-raised-button color="secondary"  (click)="toggleHelp()" style="width:200px; margin:10px;margin-left:30px;position: absolute;
              right: 250px;
              top: 35px;">Display Help</button>
              <mat-grid-list cols="4" rowHeight="80px">
                <mat-grid-tile [colspan]="4"><div class="left-align-forced">Setup Labour Costs<Br   *ngIf="DisplayHelp"><i  *ngIf="DisplayHelp"> Cost of irrigation labour for field/block setting up for this season  x hourly rate (eg moving pivot/lines, testing etc)</i></div></mat-grid-tile>
       
              </mat-grid-list>   

              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="costs_direct_water_setuplabour_owner" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="60px"  *ngIf="DisplayHelp">
                <mat-grid-tile [colspan]="4"><div class="left-align-forced padleft"><i *ngIf="DisplayHelp">Estimate of owner time lost on other activities, if unsure common measure is $150 per hour</i></div></mat-grid-tile>
                 
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_setuplabour_permanent" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_setuplabour_casual" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="50px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input matInput disabled id="costs_direct_water_setuplabour_total" class="total right-align-forced"></mat-form-field>
                  
                </mat-grid-tile>
              </mat-grid-list>  

              <mat-grid-list cols="4" rowHeight="80px">
                <mat-grid-tile [colspan]="4"><div class="left-align-forced">In-season Labour Costs<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Cost of irrigation labour for field/block during this season  x hourly rate (eg checking, turning pump on and off etc)</i></div></mat-grid-tile>
              </mat-grid-list>   

              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_seasonlabour_owner" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_seasonlabour_permanent" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_seasonlabour_casual" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="50px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input matInput disabled id="costs_direct_water_seasonlabour_total" class="total right-align-forced"></mat-form-field>
                  
                </mat-grid-tile>
              </mat-grid-list>  

       

              <mat-grid-list cols="4" rowHeight="80px">
                <mat-grid-tile [colspan]="3"><div class="left-align-forced">Water Costs<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Cost of water used during the growing season for this field/block including any prewetting water applied</i></div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_direct_water_costs"></mat-form-field></mat-grid-tile>
              </mat-grid-list>   

              <mat-grid-list cols="4" rowHeight="80px">
                <mat-grid-tile [colspan]="3"><div class="left-align-forced">Fertiliser/Herbicide Costs	<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Cost of fertiliser, manures, herbicides, fungicides or disease treatment  used on this field/block for this season</i></div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_direct_water_treatment"></mat-form-field></mat-grid-tile>
              </mat-grid-list> 

              
              <mat-grid-list cols="4" rowHeight="80px">
                <mat-grid-tile [colspan]="4"><div class="left-align-forced">Fertiliser/Herbicide Application Costs <br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Cost of fertiliser, manures, herbicides, fungicides or disease treatment application labour related to this field/block for this season</i></div></mat-grid-tile>
              </mat-grid-list>   


              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_treatment_owner" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_treatment_permanent" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_direct_water_treatment_casual" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="50px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input matInput disabled id="costs_direct_water_treatment_total" class="total right-align-forced"></mat-form-field>
                  
                </mat-grid-tile>
              </mat-grid-list>  

              
              	
              	

              <mat-grid-list cols="4" rowHeight="60px">
                <mat-grid-tile [colspan]="3"><div class="left-align-forced">Pumping Costs	<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Cost of power to run pump during season (including for any prewetting) </i></div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_direct_water_pumping"></mat-form-field></mat-grid-tile>
              </mat-grid-list>   
              <mat-grid-list cols="4" rowHeight="60px">
                <mat-grid-tile [colspan]="3"><div class="left-align-forced">Other Water Related Costs<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">eg water quality treatment, any other </i></div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_direct_water_other"></mat-form-field></mat-grid-tile>
              </mat-grid-list>   
              <mat-grid-list cols="4" rowHeight="60px">
                <mat-grid-tile [colspan]="3"><div class="left-align-forced">Machinery and Fuel Costs<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Cost of fuel and vehicles used related to this field/block for this season</i></div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_direct_water_fuel"></mat-form-field></mat-grid-tile>
              </mat-grid-list>                 

              <mat-grid-list cols="4" rowHeight="50px">
                <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
                  <h3>Direct Water Related Costs</h3></div>
                </mat-grid-tile>
                <mat-grid-tile><h3><mat-form-field><input matInput disabled id="costs_direct_total" class="total right-align-forced"></mat-form-field>
                  
                </h3></mat-grid-tile>
              </mat-grid-list>

              <h2>Other Direct Costs</h2>
              <mat-grid-list cols="4" rowHeight="60px">
                <mat-grid-tile [colspan]="3"><div class="left-align-forced">Seed Costs<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Cost to purchase seed for this season if applicable </i></div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_other_seed"></mat-form-field></mat-grid-tile>
              </mat-grid-list>  


 
              <mat-grid-list cols="4" rowHeight="60px">
                <mat-grid-tile [colspan]="4"><div class="left-align-forced">Pruning Costs<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Cost to prune this season if applicable </i></div></mat-grid-tile>
              </mat-grid-list>   	 

              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Machinery Hire Costs</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_pruning_hire" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_pruning_owner" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_pruning_permanent" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_pruning_casual" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="50px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input matInput disabled id="costs_other_pruning_total" class="total right-align-forced"></mat-form-field>
                  
                </mat-grid-tile>
              </mat-grid-list>  



              <mat-grid-list cols="4" rowHeight="50px">
                <mat-grid-tile [colspan]="4"><div class="left-align-forced">Harvest Costs<br></div></mat-grid-tile>
              </mat-grid-list>   	 

              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Machinery Hire Costs</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_harvest_hire" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_harvest_owner" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_harvest_permanent" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="30px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_other_harvest_casual" ></mat-form-field></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="4" rowHeight="50px">
                <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
                <mat-grid-tile></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input matInput disabled id="costs_other_harvest_total" class="total right-align-forced"></mat-form-field>
                  
                </mat-grid-tile>
              </mat-grid-list>  
              
              <mat-grid-list cols="4" rowHeight="60px">
                <mat-grid-tile [colspan]="3"><div class="left-align-forced">Transport Costs<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Cost of transport of harvested goods</i></div></mat-grid-tile>
                <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput formControlName="costs_other_transport"></mat-form-field></mat-grid-tile>
              </mat-grid-list>                 

              <mat-grid-list cols="4" rowHeight="50px">
                <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
                  <h3>Other Direct Costs</h3></div>
                </mat-grid-tile>
                <mat-grid-tile><h3><mat-form-field><input matInput disabled id="costs_other_total" class="total right-align-forced"></mat-form-field>
                  
                </h3></mat-grid-tile>
              </mat-grid-list>

            <h2>Water Related Indirect Costs</h2>

            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Electricty Charges<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Electricity service connection/market charges and other electricity annual type admin fees for this growing period for this field/block</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput formControlName="costs_indirect_electricity"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Fixed Water Costs <br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Fees and admin costs related to ontaining water, not dependent on how much is used., such as water license fees </i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_fixedwater"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Irrigation Infrastructure<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Irrigation/water-related infrastructure investment for field/block during growing year, eg pipes, pumps, nozzles?</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_irrigation"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Ongoing Depreciation<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Ongoing depreciation costs on irrigation infrastructure in use in field/block this growing season</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_ongoingdepreciation"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Interest<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Interest on any loans/credit cards used for irrigation infrastructure used in this field/block</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_interest"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Bank Fees<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Cost of any transactions, credit cards or loan establishment for new infrastructure undertaken during growing period for irrigating this field/block</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput  formControlName="costs_indirect_bankfees"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Insurance<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Insurance expense for water-related infrastructure during growing year – pivots, pumps, pipes, etc</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_insurance"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Repairs and Maintenance - Equipment <br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Equipment expense for repairs and maintenance on irrigation infrastructure</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_maintenance_equipment"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="4"><div class="left-align-forced">Repairs and Maintenance - Labour<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">External contractor/supplier/owner labour cost for repairs and maintenance on irrigation infrastructure</i></div></mat-grid-tile>
            </mat-grid-list>   	 
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Owner</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_indirect_maintenance_owner" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Permanent staff/manager</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_indirect_maintenance_permanent" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Casual worker</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_indirect_maintenance_casual" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Contract worker</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="costs_indirect_maintenance_contractor" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>            
            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft"><b>Sub Total</b></div></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input matInput disabled id="costs_indirect_maintenance_total" class="total right-align-forced"></mat-form-field>
                
              </mat-grid-tile>
            </mat-grid-list>  


            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Water Carting Costs<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Costs of moving water around property if applicable</i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_watercarting"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  
            <mat-grid-list cols="4" rowHeight="80px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced">Water Storage Costs<br *ngIf="DisplayHelp"><i *ngIf="DisplayHelp">Dam or bore repairs, maintenance, compliance costs etc </i></div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput  formControlName="costs_indirect_waterstorage"></mat-form-field></mat-grid-tile>
            </mat-grid-list>  



            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
                <h3>Water Related Indirect Costs</h3></div>
              </mat-grid-tile>
              <mat-grid-tile><h3><mat-form-field><input matInput disabled id="costs_indirect_total" class="total right-align-forced"></mat-form-field>
                
              </h3></mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
                <h2>Total Costs</h2></div>
              </mat-grid-tile>
              <mat-grid-tile><h2><mat-form-field><input matInput disabled id="costs_total" class="total right-align-forced"></mat-form-field>
                
              </h2></mat-grid-tile>
            </mat-grid-list>

            <h2>Income</h2>
            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Yield - Tonnes</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()" matInput type="number" formControlName="crop_yield" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="4" rowHeight="30px">
              <mat-grid-tile [colspan]="2"><div class="left-align-forced padleft">Price $/tonne</div></mat-grid-tile>
              <mat-grid-tile><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"  matInput type="number" formControlName="crop_price" ></mat-form-field></mat-grid-tile>
              <mat-grid-tile></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="60px">
              <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
                <h3>Yield Income</h3><i *ngIf="DisplayHelp">Revenue for specific harvest on this block/field</i></div>
              </mat-grid-tile>
              <mat-grid-tile><h3><mat-form-field><input matInput disabled id="income_yield" class="total right-align-forced"></mat-form-field>
                
              </h3></mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced"><h3>Water Trade Income</h3><i *ngIf="DisplayHelp">Any incomings from sales of water allocation during season </i></div></mat-grid-tile>
              <mat-grid-tile><h3><mat-form-field><input  class="right-align-forced" (blur)="calCosts()" (keyup)="calCosts()" (paste)="calCosts()"   matInput  formControlName="income_watertrade"></mat-form-field></h3></mat-grid-tile>
            </mat-grid-list>    
            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile [colspan]="3"><div class="left-align-forced"><h3>Total Income</h3></div></mat-grid-tile>
              <mat-grid-tile><h3><mat-form-field><input matInput disabled id="income_total" class="total right-align-forced"></mat-form-field></h3></mat-grid-tile>
            </mat-grid-list>    
            



            <mat-grid-list cols="4" rowHeight="50px">
              <mat-grid-tile  [colspan]="3"><div class="left-align-forced">                   
                <h2>Income minus Total Costs</h2></div>
              </mat-grid-tile>
              <mat-grid-tile><h2><mat-form-field><input matInput disabled id="income_minus_costs" class="total right-align-forced"></mat-form-field>
                
              </h2></mat-grid-tile>
            </mat-grid-list>



            <mat-grid-list cols="4" rowHeight="50px">

            </mat-grid-list>


      

          <div class="col-12 pt-5 pb-5  pr-5">
        
            <button mat-raised-button color="primary"  (click)="saveform(false)">Save</button> 
            <button mat-button [routerLink]="['/fields']" type="button" >Cancel</button>
          </div>
        </div>
            </mat-tab>
          <mat-tab  label="Options"  *ngIf="mode!='Add'">
            <div class="middleform">
              <h3> </h3>
              <br><button mat-raised-button color="outline" (click)="clearApi()">Reset Probe Data</button>
              <br><br><button mat-raised-button color="outline" (click)="saveform(true)">Copy Field</button>
              <br><br><button mat-raised-button color="outline" (click)="deleteform()">Delete Field</button>
              <br><br>
            </div>

            </mat-tab>

          </mat-tab-group>

 


 
    
      </div>
    </form>
 

