import { Injectable, Output, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
 
import { HttpHeaders } from '@angular/common/http';
import {formatDate} from '@angular/common';
 
@Injectable({
providedIn: 'root'
})

export class ApiService {
redirectUrl: string;
baseUrl:string = "https://waterlink.ruciak.net/api";
@Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
constructor(private httpClient : HttpClient) { }

//
// CROPS
//

public cropGet(ID) {
    return this.httpClient.get<any>(this.baseUrl + '/crops.php?ID=' + ID + "&sort=id&order=desc",{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(crop => {
            return crop;
        }));
}
public cropupdate( name, farmid, fieldarea, crop, cropvariety, seedrate, targetcrop, plantspacingacross, plantspacingdown, soiltype0_30cm, soiltype30_60cm,prewetting, planting, emergence50, emergence100, initiation, bulkingdate, canopyclosure, senescence, harvest, cropageatharvest, typeofirrigation, pivotspans, maxflowrate, pivotpressure, applicationrateatspeed, pivotlength, nozzletype, distributionuniformity, speedduration, applicationrate, siteid, loggerid, probetype, probelength, coordinatesforprobelat, coordinatesforprobelong, probetoplantdistance, probetoemitterdistance, probeinstalldate, datarecordingperiod, installmethod, deinstallationdate,
        crop_yield,
        crop_price,
        income_yield,
income_watertrade,
income_total,
costs_direct_water_setuplabour_owner,
costs_direct_water_setuplabour_permanent,
costs_direct_water_setuplabour_casual,
costs_direct_water_setuplabour_total,
costs_direct_water_seasonlabour_owner,
costs_direct_water_seasonlabour_permanent,
costs_direct_water_seasonlabour_casual,
costs_direct_water_seasonlabour_total,
costs_direct_water_costs,
costs_direct_water_treatment,
costs_direct_water_treatment_owner,
costs_direct_water_treatment_permanent,
costs_direct_water_treatment_casual,
costs_direct_water_treatment_total,
costs_direct_water_pumping,
costs_direct_water_other,
costs_direct_water_fuel,
costs_direct_total,
costs_other_seed,
costs_other_pruning_hire,
costs_other_pruning_owner,
costs_other_pruning_permanent,
costs_other_pruning_casual,
costs_other_pruning_total,
costs_other_harvest_hire,
costs_other_harvest_owner,
costs_other_harvest_permanent,
costs_other_harvest_casual,
costs_other_harvest_total,
costs_other_transport,
costs_other_total,
costs_indirect_electricity,
costs_indirect_fixedwater,
costs_indirect_irrigation,
costs_indirect_newdepreciation,
costs_indirect_ongoingdepreciation,
costs_indirect_interest,
costs_indirect_bankfees,
costs_indirect_insurance,
costs_indirect_maintenance_equipment,
costs_indirect_maintenance_owner,
costs_indirect_maintenance_permanent,
costs_indirect_maintenance_casual,
costs_indirect_maintenance_contractor,
costs_indirect_maintenance_total,
costs_indirect_watercarting,
costs_indirect_waterstorage,
costs_indirect_total,
costs_total,
income_minus_costs,
api_key,
api_logger,
api_userid,
    ID) {
    
    planting = this.DateFormat(planting)
    emergence50 = this.DateFormat(emergence50) 
    emergence100 = this.DateFormat(emergence100) 
    initiation= this.DateFormat(initiation)  
    bulkingdate= this.DateFormat(bulkingdate) 
    canopyclosure= this.DateFormat(canopyclosure)  
    senescence= this.DateFormat(senescence)  
    harvest= this.DateFormat(harvest) 
    cropageatharvest= this.DateFormat(cropageatharvest) 
    probeinstalldate =  this.DateFormat(probeinstalldate)
    deinstallationdate = this.DateFormat(deinstallationdate)
    
    return this.httpClient.post<any>(this.baseUrl + '/crop_update.php', { name, farmid, fieldarea, crop, cropvariety, seedrate, targetcrop, plantspacingacross, plantspacingdown, soiltype0_30cm, soiltype30_60cm,prewetting, planting, emergence50, emergence100, initiation, bulkingdate, canopyclosure, senescence, harvest, cropageatharvest, typeofirrigation, pivotspans, maxflowrate, pivotpressure, applicationrateatspeed, pivotlength, nozzletype, distributionuniformity, speedduration, applicationrate, siteid, loggerid, probetype, probelength, coordinatesforprobelat, coordinatesforprobelong, probetoplantdistance, probetoemitterdistance, probeinstalldate, datarecordingperiod, installmethod, deinstallationdate,
        crop_yield,
        crop_price,
        income_yield,
        income_watertrade,
        income_total,
        costs_direct_water_setuplabour_owner,
        costs_direct_water_setuplabour_permanent,
        costs_direct_water_setuplabour_casual,
        costs_direct_water_setuplabour_total,
        costs_direct_water_seasonlabour_owner,
        costs_direct_water_seasonlabour_permanent,
        costs_direct_water_seasonlabour_casual,
        costs_direct_water_seasonlabour_total,
        costs_direct_water_costs,
        costs_direct_water_treatment,
        costs_direct_water_treatment_owner,
        costs_direct_water_treatment_permanent,
        costs_direct_water_treatment_casual,
        costs_direct_water_treatment_total,
        costs_direct_water_pumping,
        costs_direct_water_other,
        costs_direct_water_fuel,
        costs_direct_total,
        costs_other_seed,
        costs_other_pruning_hire,
        costs_other_pruning_owner,
        costs_other_pruning_permanent,
        costs_other_pruning_casual,
        costs_other_pruning_total,
        costs_other_harvest_hire,
        costs_other_harvest_owner,
        costs_other_harvest_permanent,
        costs_other_harvest_casual,
        costs_other_harvest_total,
        costs_other_transport,
        costs_other_total,
        costs_indirect_electricity,
        costs_indirect_fixedwater,
        costs_indirect_irrigation,
        costs_indirect_newdepreciation,
        costs_indirect_ongoingdepreciation,
        costs_indirect_interest,
        costs_indirect_bankfees,
        costs_indirect_insurance,
        costs_indirect_maintenance_equipment,
        costs_indirect_maintenance_owner,
        costs_indirect_maintenance_permanent,
        costs_indirect_maintenance_casual,
        costs_indirect_maintenance_contractor,
        costs_indirect_maintenance_total,
        costs_indirect_watercarting,
        costs_indirect_waterstorage,
        costs_indirect_total,
        costs_total,
        income_minus_costs,
        api_key,
        api_logger,api_userid,ID },{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(crops => {
            return crops;
        }));
}
public cropadd( name, farmid, fieldarea, crop, cropvariety, seedrate, targetcrop, plantspacingacross, plantspacingdown, soiltype0_30cm, soiltype30_60cm,prewetting,planting, emergence50, emergence100, initiation, bulkingdate, canopyclosure, senescence, harvest, cropageatharvest, typeofirrigation, pivotspans, maxflowrate, pivotpressure, applicationrateatspeed, pivotlength, nozzletype, distributionuniformity, speedduration, applicationrate, siteid, loggerid, probetype, probelength, coordinatesforprobelat, coordinatesforprobelong, probetoplantdistance, probetoemitterdistance, probeinstalldate, datarecordingperiod, installmethod, deinstallationdate,
    crop_yield,
    crop_price,
        income_yield,
        income_watertrade,
        income_total,
        costs_direct_water_setuplabour_owner,
        costs_direct_water_setuplabour_permanent,
        costs_direct_water_setuplabour_casual,
        costs_direct_water_setuplabour_total,
        costs_direct_water_seasonlabour_owner,
        costs_direct_water_seasonlabour_permanent,
        costs_direct_water_seasonlabour_casual,
        costs_direct_water_seasonlabour_total,
        costs_direct_water_costs,
        costs_direct_water_treatment,
        costs_direct_water_treatment_owner,
        costs_direct_water_treatment_permanent,
        costs_direct_water_treatment_casual,
        costs_direct_water_treatment_total,
        costs_direct_water_pumping,
        costs_direct_water_other,
        costs_direct_water_fuel,
        costs_direct_total,
        costs_other_seed,
        costs_other_pruning_hire,
        costs_other_pruning_owner,
        costs_other_pruning_permanent,
        costs_other_pruning_casual,
        costs_other_pruning_total,
        costs_other_harvest_hire,
        costs_other_harvest_owner,
        costs_other_harvest_permanent,
        costs_other_harvest_casual,
        costs_other_harvest_total,
        costs_other_transport,
        costs_other_total,
        costs_indirect_electricity,
        costs_indirect_fixedwater,
        costs_indirect_irrigation,
        costs_indirect_newdepreciation,
        costs_indirect_ongoingdepreciation,
        costs_indirect_interest,
        costs_indirect_bankfees,
        costs_indirect_insurance,
        costs_indirect_maintenance_equipment,
        costs_indirect_maintenance_owner,
        costs_indirect_maintenance_permanent,
        costs_indirect_maintenance_casual,
        costs_indirect_maintenance_contractor,
        costs_indirect_maintenance_total,
        costs_indirect_watercarting,
        costs_indirect_waterstorage,
        costs_indirect_total,
        costs_total,
        income_minus_costs,
        api_key,
        api_logger,api_userid) {

        planting = this.DateFormat(planting)
        emergence50 = this.DateFormat(emergence50) 
        emergence100 = this.DateFormat(emergence100) 
        initiation= this.DateFormat(initiation)  
        bulkingdate= this.DateFormat(bulkingdate) 
        canopyclosure= this.DateFormat(canopyclosure)  
        senescence= this.DateFormat(senescence)  
        harvest= this.DateFormat(harvest) 
        cropageatharvest= this.DateFormat(cropageatharvest) 
        probeinstalldate =  this.DateFormat(probeinstalldate)
        deinstallationdate = this.DateFormat(deinstallationdate)

    return this.httpClient.post<any>(this.baseUrl + '/crop_add.php', {   name, farmid, fieldarea, crop, cropvariety, seedrate, targetcrop, plantspacingacross, plantspacingdown, soiltype0_30cm, soiltype30_60cm,prewetting,planting, emergence50, emergence100, initiation, bulkingdate, canopyclosure, senescence, harvest, cropageatharvest, typeofirrigation, pivotspans, maxflowrate, pivotpressure, applicationrateatspeed, pivotlength, nozzletype, distributionuniformity, speedduration, applicationrate, siteid, loggerid, probetype, probelength, coordinatesforprobelat, coordinatesforprobelong, probetoplantdistance, probetoemitterdistance, probeinstalldate, datarecordingperiod, installmethod, deinstallationdate,
        crop_yield,
        crop_price,
        income_yield,
income_watertrade,
income_total,
costs_direct_water_setuplabour_owner,
costs_direct_water_setuplabour_permanent,
costs_direct_water_setuplabour_casual,
costs_direct_water_setuplabour_total,
costs_direct_water_seasonlabour_owner,
costs_direct_water_seasonlabour_permanent,
costs_direct_water_seasonlabour_casual,
costs_direct_water_seasonlabour_total,
costs_direct_water_costs,
costs_direct_water_treatment,
costs_direct_water_treatment_owner,
costs_direct_water_treatment_permanent,
costs_direct_water_treatment_casual,
costs_direct_water_treatment_total,
costs_direct_water_pumping,
costs_direct_water_other,
costs_direct_water_fuel,
costs_direct_total,
costs_other_seed,
costs_other_pruning_hire,
costs_other_pruning_owner,
costs_other_pruning_permanent,
costs_other_pruning_casual,
costs_other_pruning_total,
costs_other_harvest_hire,
costs_other_harvest_owner,
costs_other_harvest_permanent,
costs_other_harvest_casual,
costs_other_harvest_total,
costs_other_transport,
costs_other_total,
costs_indirect_electricity,
costs_indirect_fixedwater,
costs_indirect_irrigation,
costs_indirect_newdepreciation,
costs_indirect_ongoingdepreciation,
costs_indirect_interest,
costs_indirect_bankfees,
costs_indirect_insurance,
costs_indirect_maintenance_equipment,
costs_indirect_maintenance_owner,
costs_indirect_maintenance_permanent,
costs_indirect_maintenance_casual,
costs_indirect_maintenance_contractor,
costs_indirect_maintenance_total,
costs_indirect_watercarting,
costs_indirect_waterstorage,
costs_indirect_total,
costs_total,
income_minus_costs,
api_key,
api_logger,api_userid},{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(crops => {
            return crops;
        }));
}


public cropclearapi(ID) {   
    return this.httpClient.post<any>(this.baseUrl + '/crop_clearapi.php', {ID},{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(crops => {
            return crops;
        }));
}
public cropdelete(ID) {   
    return this.httpClient.post<any>(this.baseUrl + '/crop_delete.php', {ID},{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(crops => {
            return crops;
        }));
}


//
// Alerts
//
public alertHide(ID) {
    console.log(this.baseUrl + '/alert_update.php?ID=' + ID);
    return this.httpClient.post<any>(this.baseUrl + '/alert_update.php?ID=' + ID,{ID},{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(season => {
            return season;
        }));
}

//
// SEASONS
//



public seasonUpdateCostsBySize(ID) {
    return this.httpClient.get<any>(this.baseUrl + '/functions.php?MODE=UpdateCostsBySize&ID=' + ID,{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(season => {
            return true;
        }));
}

public seasonGet(ID) {
    return this.httpClient.get<any>(this.baseUrl + '/seasons.php?ID=' + ID,{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(season => {
            return season;
        }));
}
public seasonupdate( farmid,name,
    depreciationirrigationsystem,
    alloc_licence,
    interest,
    taxesandinsurance,
    fixedsubtotal,
    powerprewetting,
    powerinseason,
    waterinclprewetting,
    fertilizer,
    diseasetreatment,
    hiredcasuallaboursetup,
    hiredcasuallabourinseason,
    hiredcasuallabourfertilizer,
    maintenanceandrepairs,
    variablesubtotal,
    ownerlaboursetup,
    ownerlabourinseason,
    ownerlabourfertilizer,
    waterqualitytreatment,
    hiddensubtotal,
    totalcosts,
    seasonyield,
    price,
    revenue,
    revenuelessexpenses, ID) {
    
 
    return this.httpClient.post<any>(this.baseUrl + '/season_update.php', { farmid,name,  
        depreciationirrigationsystem,
        alloc_licence,
        interest,
        taxesandinsurance,
        fixedsubtotal,
        powerprewetting,
        powerinseason,
        waterinclprewetting,
        fertilizer,
        diseasetreatment,
        hiredcasuallaboursetup,
        hiredcasuallabourinseason,
        hiredcasuallabourfertilizer,
        maintenanceandrepairs,
        variablesubtotal,
        ownerlaboursetup,
        ownerlabourinseason,
        ownerlabourfertilizer,
        waterqualitytreatment,
        hiddensubtotal,
        totalcosts,
        seasonyield,
        price,
        revenue,
        revenuelessexpenses, ID },{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(seasons => {
            return seasons;
        }));
}
public seasonadd( farmid,name,
    depreciationirrigationsystem,
    alloc_licence,
    interest,
    taxesandinsurance,
    fixedsubtotal,
    powerprewetting,
    powerinseason,
    waterinclprewetting,
    fertilizer,
    diseasetreatment,
    hiredcasuallaboursetup,
    hiredcasuallabourinseason,
    hiredcasuallabourfertilizer,
    maintenanceandrepairs,
    variablesubtotal,
    ownerlaboursetup,
    ownerlabourinseason,
    ownerlabourfertilizer,
    waterqualitytreatment,
    hiddensubtotal,
    totalcosts,
    seasonyield,
    price,
    revenue,
    revenuelessexpenses,) {


    return this.httpClient.post<any>(this.baseUrl + '/season_add.php', {  farmid,name, 
        depreciationirrigationsystem,
        alloc_licence,
        interest,
        taxesandinsurance,
        fixedsubtotal,
        powerprewetting,
        powerinseason,
        waterinclprewetting,
        fertilizer,
        diseasetreatment,
        hiredcasuallaboursetup,
        hiredcasuallabourinseason,
        hiredcasuallabourfertilizer,
        maintenanceandrepairs,
        variablesubtotal,
        ownerlaboursetup,
        ownerlabourinseason,
        ownerlabourfertilizer,
        waterqualitytreatment,
        hiddensubtotal,
        totalcosts,
        seasonyield,
        price,
        revenue,
        revenuelessexpenses},{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(seasons => {
            return seasons;
        }));
}

public seasondelete(ID) {   
    return this.httpClient.post<any>(this.baseUrl + '/season_delete.php', {ID},{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(seasons => {
            return seasons;
        }));
}

//
// FARMS
//

 
public farmGet(ID) {
    return this.httpClient.get<any>(this.baseUrl + '/farms.php?ID=' + ID,{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(farm => {
            return farm;
        }));
}
public farmupdate(name,customername,address, email,phone,   size, ID) {
    return this.httpClient.post<any>(this.baseUrl + '/farm_update.php', { name,customername,address, email,phone, size, ID },{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(farms => {
            return farms;
        }));
}
public farmadd(name,customername,address, email,phone,  size) {   
    return this.httpClient.post<any>(this.baseUrl + '/farm_add.php', { name,customername,address, email,phone,  size},{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(farms => {
            return farms;
        }));
}
public farmdelete(ID) {   
    return this.httpClient.post<any>(this.baseUrl + '/farm_delete.php', {ID},{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(farms => {
            return farms;
        }));
}



//
// USERS
//


public userResetPassword(username) {
    
    return this.httpClient.post<any>(this.baseUrl + '/reset.php', { username })
    .pipe(map(Users => {
    return Users;
    }));
    }
    

public userlogin(username, password, resettoken) {
    
    return this.httpClient.post<any>(this.baseUrl + '/login.php', { username, password, resettoken })
    .pipe(map(Users => {
        localStorage.setItem('token', Users[0].token);
        localStorage.setItem('admin', Users[0].admin);
        localStorage.setItem('name', Users[0].name);    
        localStorage.setItem('email', Users[0].email);    
    this.getLoggedInName.emit(true);
    return Users;
    }));
    }
    

public userGet(ID) {

    return this.httpClient.get<any>(this.baseUrl + '/users.php?ID=' + ID,{ headers: {'token':localStorage.getItem('token')}})
    .pipe(map(User => {

    return User;
    }));
    }

    public userupdate(name,email,active, admin, ID) {
        var token = document.cookie;
  
        return this.httpClient.post<any>(this.baseUrl + '/user_update.php', { name,email,active, admin, ID },{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(Users => {
        return Users;
        }));
        }
    
    


public userregistration(name,email,pwd) {
return this.httpClient.post<any>(this.baseUrl + '/register.php', { name,email, pwd })
.pipe(map(Users => {
return Users;
}));
}

public DateFormat(strDate){

    if (strDate == "" || formatDate(strDate,'yyyy-MM-dd','en') == "1970-01-01"){return "";}else{return formatDate(strDate,'yyyy-MM-dd','en');}


  }

  public userdelete(ID) {   
    return this.httpClient.post<any>(this.baseUrl + '/user_delete.php', {ID},{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(Users => {
            return Users;
        }));
}




// Dashboard
public dashboardGetFarm(ID) {
    return this.httpClient.get<any>(this.baseUrl + '/farms_dashboard.php?ID=' + ID,{ headers: {'token':localStorage.getItem('token')}})
        .pipe(map(farm => {
            return farm;
        }));
}

 

//token
setToken(token: string) {
localStorage.setItem('token', token);
}
getToken() {
return localStorage.getItem('token');
}
deleteToken() {
localStorage.removeItem('token');
}
isLoggedIn() {
const usertoken = this.getToken();
if (usertoken != null) {
return true
}
return false;
}
}