import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';
 
import { ApiService } from '../../api.service';
 
 
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import {MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';

@Component({
  selector: 'app-usereditor',
  templateUrl: './usereditor.component.html',
  styleUrls: ['./usereditor.component.css']
})
export class UsereditorComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  id: number;

  angForm: FormGroup;
  constructor(private fb: FormBuilder,private dataService: ApiService, private router: Router, private route: ActivatedRoute,private _snackBar: MatSnackBar) {

    this.angForm = this.fb.group({
      email: ['', [Validators.required,Validators.minLength(1), Validators.email]],
      active: ['', Validators.required],
      name: ['', Validators.required],
      admin: ['', Validators.required]
      });

      
  
  
  
      }
 
  
  ngOnInit() {

 
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));
   


  this.dataService.userGet(this.id)
    .pipe(first())
    .subscribe(
    data => {
 
      this.angForm = this.fb.group({
        email: [data.users[0].email, [Validators.required,  Validators.email]],
        active: [data.users[0].active],
        admin: [data.users[0].admin],
        name: [data.users[0].name, Validators.required]
        });
        
 
 
    },
    error => {
    alert("User not found")
    });

 
    
  }

  deleteform()
 {
  if (confirm('Are you sure you want to delete user ' + this.angForm.value.name + '' )){
    this.dataService.userdelete(this.id)
    .subscribe(
    data => {
      this._snackBar.open('User ' + this.angForm.value.name + ' deleted','OK',{
        duration: 20 * 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    this.router.navigate(['users']);
  
    },
    error => {
    });
    
  }
 }

  postdata(angForm1)
  {
  this.dataService.userupdate(angForm1.value.name,angForm1.value.email,angForm1.value.active,angForm1.value.admin, this.id)
  .subscribe(
  data => {
    this._snackBar.open('User ' + angForm1.value.name + ' updated','OK',{
      duration: 20 * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  this.router.navigate(['users']);

  },
  
  error => {
  });
  }
  
  get email() { return this.angForm.get('email'); }
  get password() { return this.angForm.get('password'); }
  get name() { return this.angForm.get('name'); }
  }
 