<div class="container" id="container" style="display:none;">

 
  <div class="row" >
    <div class="col-9">
      <h2><button [routerLink]="['/dashboard']"  mat-raised-button >Dashboard</button> Fields</h2>
    </div>
    <div class="col-3 text-right">
      <button  [routerLink]="['/fields/add']" mat-raised-button color="primary">Add Field</button>
    </div>
  </div>
 


<div class="example-container mat-elevation-z8">
    <div class="example-loading-shade"
         *ngIf="isLoadingResults || isRateLimitReached">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
 
    </div>
  
    <div class="the-table-container">
  
      <table mat-table [dataSource]="data" class="example-table"
             matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
 
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row">{{row.number}}</td>
        </ng-container>
  
 
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>id</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>
   
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Field Name</th>
          <td mat-cell *matCellDef="let row">
            <a [title]="row.name + ' details'" [routerLink]="['/fields', row.id]">
              {{ row.name }}
            </a>
             </td>
        </ng-container>
  
        <ng-container matColumnDef="farmname">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header>Farm
          </th>
 
          <td mat-cell *matCellDef="let row">{{row.farmname}}</td>
        </ng-container>

  


        <ng-container matColumnDef="seasonname">
          <th mat-header-cell *matHeaderCellDef>Season</th>
          <td mat-cell *matCellDef="let row">{{row.seasonname}}</td>
        </ng-container>

   
 

        <ng-container matColumnDef="crop">
          <th mat-header-cell *matHeaderCellDef>Crop</th>
          <td mat-cell *matCellDef="let row">{{row.crop}}</td>
        </ng-container>
        <ng-container matColumnDef="cropvariety">
          <th mat-header-cell *matHeaderCellDef>Crop Variety</th>
          <td mat-cell *matCellDef="let row">{{row.cropvariety}}</td>
        </ng-container>
        <ng-container matColumnDef="plantingdate">
          <th mat-header-cell *matHeaderCellDef>Planting Date</th>
          <td mat-cell *matCellDef="let row">{{row.plantingdate}}</td>
        </ng-container>



        <ng-container matColumnDef="farmnameFilter">
          <th mat-header-cell *matHeaderCellDef >
            <div class="filter">
              <mat-select [(value)]="filterFarmName" matNativeControl (selectionChange)="farmChangeValue($event.value)" aria-placeholder="123">
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let farm of farmsFilter" [value]="farm">{{farm}}</mat-option>
              </mat-select> 
              </div>
          </th>
 
          <td mat-cell *matCellDef="let row">{{row.farmname}}</td>
        </ng-container>


        <ng-container matColumnDef="blank">
          <th mat-header-cell *matHeaderCellDef >
      
          </th>
 
 
        </ng-container>
 

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsFilter"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  
    <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
  </div>
</div>
