import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';

import { ApiService } from '../../api.service';

 
import { Router, ActivatedRoute } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
 
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ThisReceiver } from '@angular/compiler';
import { formatNumber } from '@angular/common';

const regexNumbers = /\-?\d*\.?\d{1,2}/;

@Component({
  selector: 'app-seasoneditor',
  templateUrl: './seasoneditor.component.html',
  styleUrls: ['./seasoneditor.component.css'],
  providers: [
     {provide: MAT_DATE_LOCALE, useValue: 'en-au'},
     {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class SeasoneditorComponent implements OnInit {
 
  id: number;
  mode: string  = "Edit";
  angForm: FormGroup;
  farms = [];
  displayFinanicals = false;

  constructor(private fb: FormBuilder,private dataService: ApiService, private router: Router, private route: ActivatedRoute,private _snackBar: MatSnackBar ) {

    this.angForm = this.fb.group({
      farmid: ['', Validators.required],
      name: ['', Validators.required],
     
      depreciationirrigationsystem: ['', Validators.required],
alloc_licence: ['', Validators.required],
interest: ['', Validators.required],
taxesandinsurance: ['', Validators.required],
fixedsubtotal: ['', Validators.required],
powerprewetting: ['', Validators.required],
powerinseason: ['', Validators.required],
waterinclprewetting: ['', Validators.required],
fertilizer: ['', Validators.required],
diseasetreatment: ['', Validators.required],
hiredcasuallaboursetup: ['', Validators.required],
hiredcasuallabourinseason: ['', Validators.required],
hiredcasuallabourfertilizer: ['', Validators.required],
maintenanceandrepairs: ['', Validators.required],
variablesubtotal: ['', Validators.required],
ownerlaboursetup: ['', Validators.required],
ownerlabourinseason: ['', Validators.required],
ownerlabourfertilizer: ['', Validators.required],
waterqualitytreatment: ['', Validators.required],
hiddensubtotal: ['', Validators.required],
totalcosts: ['', Validators.required],
yield: ['', Validators.required],
price: ['', Validators.required],
revenue: ['', Validators.required],
revenuelessexpenses: ['', Validators.required],
crop_yield: ['0', Validators.required],
crop_price: ['0', Validators.required],
income_yield: ['0', Validators.required],
income_watertrade: ['0', Validators.required],
income_total: ['0', Validators.required],
costs_direct_water_setuplabour_owner: ['0', Validators.required],
costs_direct_water_setuplabour_permanent: ['0', Validators.required],
costs_direct_water_setuplabour_casual: ['0', Validators.required],
costs_direct_water_setuplabour_total: ['0', Validators.required],
costs_direct_water_seasonlabour_owner: ['0', Validators.required],
costs_direct_water_seasonlabour_permanent: ['0', Validators.required],
costs_direct_water_seasonlabour_casual: ['0', Validators.required],
costs_direct_water_seasonlabour_total: ['0', Validators.required],
costs_direct_water_costs: ['0', Validators.required],
costs_direct_water_treatment: ['0', Validators.required],
costs_direct_water_treatment_owner: ['0', Validators.required],
costs_direct_water_treatment_permanent: ['0', Validators.required],
costs_direct_water_treatment_casual: ['0', Validators.required],
costs_direct_water_treatment_total: ['0', Validators.required],
costs_direct_water_pumping: ['0', Validators.required],
costs_direct_water_other: ['0', Validators.required],
costs_direct_water_fuel: ['0', Validators.required],
costs_direct_total: ['0', Validators.required],
costs_other_seed: ['0', Validators.required],
costs_other_pruning_hire: ['0', Validators.required],
costs_other_pruning_owner: ['0', Validators.required],
costs_other_pruning_permanent: ['0', Validators.required],
costs_other_pruning_casual: ['0', Validators.required],
costs_other_pruning_total: ['0', Validators.required],
costs_other_harvest_hire: ['0', Validators.required],
costs_other_harvest_owner: ['0', Validators.required],
costs_other_harvest_permanent: ['0', Validators.required],
costs_other_harvest_casual: ['0', Validators.required],
costs_other_harvest_total: ['0', Validators.required],
costs_other_transport: ['0', Validators.required],
costs_other_total: ['0', Validators.required],
costs_indirect_electricity: ['0', Validators.required],
costs_indirect_fixedwater: ['0', Validators.required],
costs_indirect_irrigation: ['0', Validators.required],
costs_indirect_newdepreciation: ['0', Validators.required],
costs_indirect_ongoingdepreciation: ['0', Validators.required],
costs_indirect_interest: ['0', Validators.required],
costs_indirect_bankfees: ['0', Validators.required],
costs_indirect_insurance: ['0', Validators.required],
costs_indirect_maintenance_equipment: ['0', Validators.required],
costs_indirect_maintenance_owner: ['0', Validators.required],
costs_indirect_maintenance_permanent: ['0', Validators.required],
costs_indirect_maintenance_casual: ['0', Validators.required],
costs_indirect_maintenance_contractor: ['0', Validators.required],
costs_indirect_maintenance_total: ['0', Validators.required],
costs_indirect_watercarting: ['0', Validators.required],
costs_indirect_waterstorage: ['0', Validators.required],
costs_indirect_total: ['0', Validators.required],
costs_total: ['0', Validators.required],
income_minus_costs: ['0', Validators.required]
      });

      
  
  
  
      }
 
  
  ngOnInit() {

 
    const routeParams = this.route.snapshot.paramMap;


      this.dataService.farmGet('')
      .pipe(first())
      .subscribe(
      data => {
        this.farms = data.farms;
      });





    if(routeParams.get('id') == null){
      this.mode = "Add"

    }else{
      this.id = Number(routeParams.get('id'));
    


  this.dataService.seasonGet(this.id)
    .pipe(first())
    .subscribe(
    data => {

        this.angForm = this.fb.group({
        farmid: [data.seasons[0].farmid, Validators.required],
        name: [data.seasons[0].name, Validators.required],
        depreciationirrigationsystem: [data.seasons[0].depreciationirrigationsystem, Validators.required],
        alloc_licence: [data.seasons[0].alloc_licence, Validators.required],
        interest: [data.seasons[0].interest, Validators.required],
        taxesandinsurance: [data.seasons[0].taxesandinsurance, Validators.required],
        fixedsubtotal: [data.seasons[0].fixedsubtotal, Validators.required],
        powerprewetting: [data.seasons[0].powerprewetting, Validators.required],
        powerinseason: [data.seasons[0].powerinseason, Validators.required],
        waterinclprewetting: [data.seasons[0].waterinclprewetting, Validators.required],
        fertilizer: [data.seasons[0].fertilizer, Validators.required],
        diseasetreatment: [data.seasons[0].diseasetreatment, Validators.required],
        hiredcasuallaboursetup: [data.seasons[0].hiredcasuallaboursetup, Validators.required],
        hiredcasuallabourinseason: [data.seasons[0].hiredcasuallabourinseason, Validators.required],
        hiredcasuallabourfertilizer: [data.seasons[0].hiredcasuallabourfertilizer, Validators.required],
        maintenanceandrepairs: [data.seasons[0].maintenanceandrepairs, Validators.required],
        variablesubtotal: [data.seasons[0].variablesubtotal, Validators.required],
        ownerlaboursetup: [data.seasons[0].ownerlaboursetup, Validators.required],
        ownerlabourinseason: [data.seasons[0].ownerlabourinseason, Validators.required],
        ownerlabourfertilizer: [data.seasons[0].ownerlabourfertilizer, Validators.required],
        waterqualitytreatment: [data.seasons[0].waterqualitytreatment, Validators.required],
        hiddensubtotal: [data.seasons[0].hiddensubtotal, Validators.required],
        totalcosts: [data.seasons[0].totalcosts, Validators.required],
        yield: [data.seasons[0].yield, Validators.required],
        price: [data.seasons[0].price, Validators.required],
        revenue: [data.seasons[0].revenue, Validators.required],
        revenuelessexpenses: [data.seasons[0].revenuelessexpenses, Validators.required],
        });
    },
    error => {
      alert("Season not found")
    });
  }
 
    
  }
 
  calCosts() {

     
    this.angForm.controls['fixedsubtotal'].setValue((
      parseFloat(this.angForm.value.taxesandinsurance) +      
      parseFloat(this.angForm.value.interest) +      
      parseFloat(this.angForm.value.alloc_licence) +      
      parseFloat(this.angForm.value.depreciationirrigationsystem)).toFixed(2));


      this.angForm.controls['hiddensubtotal'].setValue((
        parseFloat(this.angForm.value.ownerlaboursetup) +      
        parseFloat(this.angForm.value.ownerlabourinseason) +      
        parseFloat(this.angForm.value.ownerlabourfertilizer) +      
        parseFloat(this.angForm.value.waterqualitytreatment)).toFixed(2));

      this.angForm.controls['variablesubtotal'].setValue((
          parseFloat(this.angForm.value.powerprewetting) +      
          parseFloat(this.angForm.value.powerinseason) +      
          parseFloat(this.angForm.value.waterinclprewetting) +      
          parseFloat(this.angForm.value.fertilizer) +      
          parseFloat(this.angForm.value.diseasetreatment) +      
          parseFloat(this.angForm.value.hiredcasuallaboursetup) +      
          parseFloat(this.angForm.value.hiredcasuallabourinseason) +      
          parseFloat(this.angForm.value.hiredcasuallabourfertilizer) +      
          parseFloat(this.angForm.value.maintenanceandrepairs)).toFixed(2));

      this.angForm.controls['totalcosts'].setValue((
            parseFloat(this.angForm.value.fixedsubtotal) +      
            parseFloat(this.angForm.value.hiddensubtotal) +      
            parseFloat(this.angForm.value.variablesubtotal)).toFixed(2));
         

            this.angForm.controls['revenue'].setValue((
              parseFloat(this.angForm.value.yield) *      
              parseFloat(this.angForm.value.price)).toFixed(2));      


            this.angForm.controls['revenuelessexpenses'].setValue((
              parseFloat(this.angForm.value.revenue) -      
              parseFloat(this.angForm.value.totalcosts)).toFixed(2));            




 }
  
 deleteform()
 {
  if (confirm('Are you sure you want to delete season ' + this.angForm.value.name + ' linking all linked fields' )){
    this.dataService.seasondelete(this.id)
    .subscribe(
    data => {
      this._snackBar.open('Season ' + this.angForm.value.name + ' deleted','',{
      duration: 3 * 1000,
      panelClass: ['btn-success']
    });
    this.router.navigate(['seasons']);
  
    },
    error => {
    });
    
  }
 }

  saveform(copy, redirect, updateCropCosts)
  {

    var seasonName = this.angForm.value.name 
    var strMode = ' added'

    if(this.mode == "Add" || copy){
      if(copy){
        seasonName = seasonName + ' (COPY)'
        strMode = ' copied'
      }


      this.dataService.seasonadd(this.angForm.value.farmid,seasonName,
        this.angForm.value.depreciationirrigationsystem,
        this.angForm.value.alloc_licence,
        this.angForm.value.interest,
        this.angForm.value.taxesandinsurance,
        this.angForm.value.fixedsubtotal,
        this.angForm.value.powerprewetting,
        this.angForm.value.powerinseason,
        this.angForm.value.waterinclprewetting,
        this.angForm.value.fertilizer,
        this.angForm.value.diseasetreatment,
        this.angForm.value.hiredcasuallaboursetup,
        this.angForm.value.hiredcasuallabourinseason,
        this.angForm.value.hiredcasuallabourfertilizer,
        this.angForm.value.maintenanceandrepairs,
        this.angForm.value.variablesubtotal,
        this.angForm.value.ownerlaboursetup,
        this.angForm.value.ownerlabourinseason,
        this.angForm.value.ownerlabourfertilizer,
        this.angForm.value.waterqualitytreatment,
        this.angForm.value.hiddensubtotal,
        this.angForm.value.totalcosts,
        this.angForm.value.yield,
        this.angForm.value.price,
        this.angForm.value.revenue,
        this.angForm.value.revenuelessexpenses)
      .subscribe(
      data => {
        this._snackBar.open('Season ' + this.angForm.value.name + strMode,'',{
        duration: 3 * 1000,
        panelClass: ['btn-success']
      });
      if (redirect){
        this.router.navigate(['seasons']);
      }
      },
      error => {
      });


    }else{
 
      this.dataService.seasonupdate(
          this.angForm.value.farmid,
          this.angForm.value.name,
          this.angForm.value.depreciationirrigationsystem,
          this.angForm.value.alloc_licence,
          this.angForm.value.interest,
          this.angForm.value.taxesandinsurance,
          this.angForm.value.fixedsubtotal,
          this.angForm.value.powerprewetting,
          this.angForm.value.powerinseason,
          this.angForm.value.waterinclprewetting,
          this.angForm.value.fertilizer,
          this.angForm.value.diseasetreatment,
          this.angForm.value.hiredcasuallaboursetup,
          this.angForm.value.hiredcasuallabourinseason,
          this.angForm.value.hiredcasuallabourfertilizer,
          this.angForm.value.maintenanceandrepairs,
          this.angForm.value.variablesubtotal,
          this.angForm.value.ownerlaboursetup,
          this.angForm.value.ownerlabourinseason,
          this.angForm.value.ownerlabourfertilizer,
          this.angForm.value.waterqualitytreatment,
          this.angForm.value.hiddensubtotal,
          this.angForm.value.totalcosts,
          this.angForm.value.yield,
          this.angForm.value.price,
          this.angForm.value.revenue,
          this.angForm.value.revenuelessexpenses, this.id)
        .subscribe(
        data => {

          if (updateCropCosts){
            this.dataService.seasonUpdateCostsBySize(this.id)
            .subscribe(
            data => {
              this._snackBar.open('Costs updated to crops by size ','',{
              duration: 3 * 1000,
              panelClass: ['btn-success']
               });
            },
            error => {
            });

          }else{  
            this._snackBar.open('Season ' + this.angForm.value.name + ' updated','',{
              duration: 3 * 1000,
              panelClass: ['btn-success'] });

           }

        if (redirect){
         this.router.navigate(['seasons']);
        }
        },
        error => {
        });
    }
  }

 
  }

   