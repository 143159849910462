import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup,  FormBuilder, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';

import { ApiService } from '../../api.service';

import {merge, Observable, of as observableOf} from 'rxjs';
 
import { Router, ActivatedRoute } from '@angular/router';
import {MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

 
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
 
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

 


const regexNumbers = /\-?\d*\.?\d{1,2}/;

@Component({
  selector: 'app-cropeditor',
  templateUrl: './cropeditor.component.html',
  styleUrls: ['./cropeditor.component.css'],
  providers: [
     {provide: MAT_DATE_LOCALE, useValue: 'en-au'},
     {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class cropeditorComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  displayedColumns: string[] = [];
  apidata: apidata | null;
  data: apidata[] = [];
  selectedapi: "";
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  selectedGroup = 'Seasonal Weather Tracker';
  selected = 'Daily Air Temperature';
  DataTabIndex: number;
  graphurl: SafeUrl;
  graphLoaded: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

 
 
 

  transform(value:string): string {
    let first = value.substr(0,1).toUpperCase();
    return first + value.substr(1); 
  }

  
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
 

 

  displaySubReports: boolean = true;
  DisplayHelp: boolean = false;
  id: number;
  mode: string  = "View";
  angForm: FormGroup;
  farms = [];
  displayNavigation: boolean = false;
  displayWelcome : boolean = false;
 
  constructor(public sanitizer: DomSanitizer,private _httpClient: HttpClient,private fb: FormBuilder,private dataService: ApiService, private router: Router, private route: ActivatedRoute,private _snackBar: MatSnackBar ) {
 

   
   

    
    

 
      
             //Check for Fields
             this.dataService.cropGet('').pipe(first()).subscribe(data => { 
              if (data.total_count == 0){
       
                this.displayWelcome = true;

              }else{
                this.displayNavigation = true;
               }
            });

            
            this.dataService.farmGet('')
            .pipe(first())
            .subscribe(
            data => {
              this.farms = data.farms;
              if (data.total_count == 1){
                this.angForm.controls['farmid'].setValue(this.farms[0].id);
              }
            });
        



            this.angForm = this.fb.group({
   
              name: ['', Validators.required],
              farmid: ['', Validators.required],
              fieldarea: ['', Validators.required],
              crop: [''],
              cropvariety: [''],
              seedrate: [''],
              targetcrop: [''],
              plantspacingacross: [''],
              plantspacingdown: [''],
              soiltype0_30cm: [''],
              soiltype30_60cm: [''],
              prewetting: ['0'],
              planting: ['', Validators.required],
              emergence50: [''],
              emergence100: [''],
              initiation: [''],
              bulkingdate: [''],
              canopyclosure: [''],
              senescence: [''],
              harvest: ['', Validators.required],
              cropageatharvest: [''],
              typeofirrigation: [''],
              pivotspans: [''],
              maxflowrate: [''],
              pivotpressure: [''],
              applicationrateatspeed: [''],
              pivotlength: [''],
              nozzletype: [''],
              distributionuniformity: [''],
              speedduration: [''],
              applicationrate: [''],
              siteid: [''],
              loggerid: [''],
              probetype: [''],
              probelength: [''],
              coordinatesforprobelat: [''],
              coordinatesforprobelong: [''],
              probetoplantdistance: [''],
              probetoemitterdistance: [''],
              probeinstalldate: [''],
              datarecordingperiod: [''],
              installmethod: [''],
              deinstallationdate: [''],
              crop_yield: ['0', Validators.required],
              crop_price: ['0', Validators.required],
              income_yield: ['0', Validators.required],
              income_watertrade: ['0', Validators.required],
              income_total: ['0', Validators.required],
              costs_direct_water_setuplabour_owner: ['0', Validators.required],
              costs_direct_water_setuplabour_permanent: ['0', Validators.required],
              costs_direct_water_setuplabour_casual: ['0', Validators.required],
              costs_direct_water_setuplabour_total: ['0', Validators.required],
              costs_direct_water_seasonlabour_owner: ['0', Validators.required],
              costs_direct_water_seasonlabour_permanent: ['0', Validators.required],
              costs_direct_water_seasonlabour_casual: ['0', Validators.required],
              costs_direct_water_seasonlabour_total: ['0', Validators.required],
              costs_direct_water_costs: ['0', Validators.required],
              costs_direct_water_treatment: ['0', Validators.required],
              costs_direct_water_treatment_owner: ['0', Validators.required],
              costs_direct_water_treatment_permanent: ['0', Validators.required],
              costs_direct_water_treatment_casual: ['0', Validators.required],
              costs_direct_water_treatment_total: ['0', Validators.required],
              costs_direct_water_pumping: ['0', Validators.required],
              costs_direct_water_other: ['0', Validators.required],
              costs_direct_water_fuel: ['0', Validators.required],
              costs_direct_total: ['0', Validators.required],
              costs_other_seed: ['0', Validators.required],
              costs_other_pruning_hire: ['0', Validators.required],
              costs_other_pruning_owner: ['0', Validators.required],
              costs_other_pruning_permanent: ['0', Validators.required],
              costs_other_pruning_casual: ['0', Validators.required],
              costs_other_pruning_total: ['0', Validators.required],
              costs_other_harvest_hire: ['0', Validators.required],
              costs_other_harvest_owner: ['0', Validators.required],
              costs_other_harvest_permanent: ['0', Validators.required],
              costs_other_harvest_casual: ['0', Validators.required],
              costs_other_harvest_total: ['0', Validators.required],
              costs_other_transport: ['0', Validators.required],
              costs_other_total: ['0', Validators.required],
              costs_indirect_electricity: ['0', Validators.required],
              costs_indirect_fixedwater: ['0', Validators.required],
              costs_indirect_irrigation: ['0', Validators.required],
              costs_indirect_newdepreciation: ['0', Validators.required],
              costs_indirect_ongoingdepreciation: ['0', Validators.required],
              costs_indirect_interest: ['0', Validators.required],
              costs_indirect_bankfees: ['0', Validators.required],
              costs_indirect_insurance: ['0', Validators.required],
              costs_indirect_maintenance_equipment: ['0', Validators.required],
              costs_indirect_maintenance_owner: ['0', Validators.required],
              costs_indirect_maintenance_permanent: ['0', Validators.required],
              costs_indirect_maintenance_casual: ['0', Validators.required],
              costs_indirect_maintenance_contractor: ['0', Validators.required],
              costs_indirect_maintenance_total: ['0', Validators.required],
              costs_indirect_watercarting: ['0', Validators.required],
              costs_indirect_waterstorage: ['0', Validators.required],
              costs_indirect_total: ['0', Validators.required],
              costs_total: ['0', Validators.required],
              income_minus_costs: ['0', Validators.required],
              api_key: ['', Validators.required],
              api_logger: ['', Validators.required],
              api_userid: ['', Validators.required]
              });
        
  
  
      }
 
      ngAfterViewInit() {
        this.apidata = new apidata(this._httpClient);

 
         
      }
   
    
  ngOnInit() {


 

    const routeParams = this.route.snapshot.paramMap;





    if(routeParams.get('id') == null){
      this.mode = "Add"

    }else{
      this.id = Number(routeParams.get('id'));
    




  this.dataService.cropGet(this.id)
    .pipe(first())
    .subscribe(
    data => {
 
        this.angForm = this.fb.group({
 
        name: [data.crops[0].name, Validators.required],
        farmid: [data.crops[0].farmid, Validators.required],
        fieldarea: [data.crops[0].fieldarea, Validators.required],
        crop: [data.crops[0].crop],
        cropvariety: [data.crops[0].cropvariety],
        seedrate: [data.crops[0].seedrate],
        targetcrop: [data.crops[0].targetcrop],
        plantspacingacross: [data.crops[0].plantspacingacross],
        plantspacingdown: [data.crops[0].plantspacingdown],
        soiltype0_30cm: [data.crops[0].soiltype0_30cm],
        soiltype30_60cm: [data.crops[0].soiltype30_60cm],
        prewetting: [data.crops[0].prewetting],
        planting: [data.crops[0].planting, Validators.required],
        emergence50: [data.crops[0].emergence50],
        emergence100: [data.crops[0].emergence100],
        initiation: [data.crops[0].initiation],
        bulkingdate: [data.crops[0].bulkingdate],
        canopyclosure: [data.crops[0].canopyclosure],
        senescence: [data.crops[0].senescence],
        harvest: [data.crops[0].harvest, Validators.required],
        cropageatharvest: [data.crops[0].cropageatharvest],
        typeofirrigation: [data.crops[0].typeofirrigation],
        pivotspans: [data.crops[0].pivotspans],
        maxflowrate: [data.crops[0].maxflowrate],
        pivotpressure: [data.crops[0].pivotpressure],
        applicationrateatspeed: [data.crops[0].applicationrateatspeed],
        pivotlength: [data.crops[0].pivotlength],
        nozzletype: [data.crops[0].nozzletype],
        distributionuniformity: [data.crops[0].distributionuniformity],
        speedduration: [data.crops[0].speedduration],
        applicationrate: [data.crops[0].applicationrate],
        siteid: [data.crops[0].siteid],
        loggerid: [data.crops[0].loggerid],
        probetype: [data.crops[0].probetype],
        probelength: [data.crops[0].probelength],
        coordinatesforprobelat: [data.crops[0].coordinatesforprobelat],
        coordinatesforprobelong: [data.crops[0].coordinatesforprobelong],
        probetoplantdistance: [data.crops[0].probetoplantdistance],
        probetoemitterdistance: [data.crops[0].probetoemitterdistance],
        probeinstalldate: [data.crops[0].probeinstalldate],
        datarecordingperiod: [data.crops[0].datarecordingperiod],
        installmethod: [data.crops[0].installmethod],
        deinstallationdate: [data.crops[0].deinstallationdate],
        crop_yield: [data.crops[0].crop_yield ],
        crop_price: [data.crops[0].crop_price ],
        income_yield: [data.crops[0].income_yield ],
        income_watertrade: [data.crops[0].income_watertrade ],
        income_total: [data.crops[0].income_total ],
        costs_direct_water_setuplabour_owner: [data.crops[0].costs_direct_water_setuplabour_owner  ],
        costs_direct_water_setuplabour_permanent: [data.crops[0].costs_direct_water_setuplabour_permanent  ],
        costs_direct_water_setuplabour_casual: [data.crops[0].costs_direct_water_setuplabour_casual  ],
        costs_direct_water_setuplabour_total: [data.crops[0].costs_direct_water_setuplabour_total  ],
        costs_direct_water_seasonlabour_owner: [data.crops[0].costs_direct_water_seasonlabour_owner  ],
        costs_direct_water_seasonlabour_permanent: [data.crops[0].costs_direct_water_seasonlabour_permanent  ],
        costs_direct_water_seasonlabour_casual: [data.crops[0].costs_direct_water_seasonlabour_casual  ],
        costs_direct_water_seasonlabour_total: [data.crops[0].costs_direct_water_seasonlabour_total  ],
        costs_direct_water_costs: [data.crops[0].costs_direct_water_costs  ],
        costs_direct_water_treatment: [data.crops[0].costs_direct_water_treatment  ],
        costs_direct_water_treatment_owner: [data.crops[0].costs_direct_water_treatment_owner  ],
        costs_direct_water_treatment_permanent: [data.crops[0].costs_direct_water_treatment_permanent  ],
        costs_direct_water_treatment_casual: [data.crops[0].costs_direct_water_treatment_casual  ],
        costs_direct_water_treatment_total: [data.crops[0].costs_direct_water_treatment_total  ],
        costs_direct_water_pumping: [data.crops[0].costs_direct_water_pumping  ],
        costs_direct_water_other: [data.crops[0].costs_direct_water_other  ],
        costs_direct_water_fuel: [data.crops[0].costs_direct_water_fuel  ],
        costs_direct_total: [data.crops[0].costs_direct_total  ],
        costs_other_seed: [data.crops[0].costs_other_seed  ],
        costs_other_pruning_hire: [data.crops[0].costs_other_pruning_hire  ],
        costs_other_pruning_owner: [data.crops[0].costs_other_pruning_owner  ],
        costs_other_pruning_permanent: [data.crops[0].costs_other_pruning_permanent  ],
        costs_other_pruning_casual: [data.crops[0].costs_other_pruning_casual  ],
        costs_other_pruning_total: [data.crops[0].costs_other_pruning_total  ],
        costs_other_harvest_hire: [data.crops[0].costs_other_harvest_hire  ],
        costs_other_harvest_owner: [data.crops[0].costs_other_harvest_owner  ],
        costs_other_harvest_permanent: [data.crops[0].costs_other_harvest_permanent  ],
        costs_other_harvest_casual: [data.crops[0].costs_other_harvest_casual  ],
        costs_other_harvest_total: [data.crops[0].costs_other_harvest_total  ],
        costs_other_transport: [data.crops[0].costs_other_transport  ],
        costs_other_total: [data.crops[0].costs_other_total  ],
        costs_indirect_electricity: [data.crops[0].costs_indirect_electricity  ],
        costs_indirect_fixedwater: [data.crops[0].costs_indirect_fixedwater  ],
        costs_indirect_irrigation: [data.crops[0].costs_indirect_irrigation  ],
        costs_indirect_newdepreciation: [data.crops[0].costs_indirect_newdepreciation  ],
        costs_indirect_ongoingdepreciation: [data.crops[0].costs_indirect_ongoingdepreciation  ],
        costs_indirect_interest: [data.crops[0].costs_indirect_interest  ],
        costs_indirect_bankfees: [data.crops[0].costs_indirect_bankfees  ],
        costs_indirect_insurance: [data.crops[0].costs_indirect_insurance  ],
        costs_indirect_maintenance_equipment: [data.crops[0].costs_indirect_maintenance_equipment  ],
        costs_indirect_maintenance_owner: [data.crops[0].costs_indirect_maintenance_owner  ],
        costs_indirect_maintenance_permanent: [data.crops[0].costs_indirect_maintenance_permanent  ],
        costs_indirect_maintenance_casual: [data.crops[0].costs_indirect_maintenance_casual  ],
        costs_indirect_maintenance_contractor: [data.crops[0].costs_indirect_maintenance_contractor  ],
        costs_indirect_maintenance_total: [data.crops[0].costs_indirect_maintenance_total  ],
        costs_indirect_watercarting: [data.crops[0].costs_indirect_watercarting  ],
        costs_indirect_waterstorage: [data.crops[0].costs_indirect_waterstorage  ],
        costs_indirect_total: [data.crops[0].costs_indirect_total  ],
        costs_total: [data.crops[0].costs_total  ],
        income_minus_costs: [data.crops[0].income_minus_costs  ],     
        api_key: [data.crops[0].api_key  , Validators.required],    
        api_logger: [data.crops[0].api_logger  , Validators.required],   
        api_userid: [data.crops[0].api_userid  , Validators.required],                     
        });
   
        if (routeParams.get('refresh') == "refresh"){
          debugger
          this.dataService.cropclearapi(this.id).subscribe(
            data => {

              this._snackBar.open(data.crops[0].name + ' probe data updated','OK',{
                duration: 20 * 1000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition
                });
            },
            error => {
              this._snackBar.open(data.crops[0].name + ' probe data updated','OK',{
                duration: 20 * 1000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition
                });
    
            });
          }
    
 
   
    },
    error => {
      alert("Field not found")
    });
  }
 
    
  }

  numberWithCommas(num) {
    num = num.replace('NaN','0')
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
 
  calCosts() {

   
if (document.getElementById("income_yield") == null){
  return
}


    (<HTMLInputElement>document.getElementById("income_yield")).value = 
      (
        parseFloat(this.angForm.value.crop_yield) *      
        parseFloat(this.angForm.value.crop_price)
      ).toFixed(2);


    (<HTMLInputElement>document.getElementById("income_total")).value = 
      (
        parseFloat(this.angForm.value.income_watertrade) + 
        parseFloat((<HTMLInputElement>document.getElementById("income_yield")).value)
      ).toFixed(2); 



      (<HTMLInputElement>document.getElementById("costs_direct_water_setuplabour_total")).value = 
      (
        parseFloat(this.angForm.value.costs_direct_water_setuplabour_owner) +      
        parseFloat(this.angForm.value.costs_direct_water_setuplabour_permanent) +      
        parseFloat(this.angForm.value.costs_direct_water_setuplabour_casual)
      ).toFixed(2);
   

      (<HTMLInputElement>document.getElementById("costs_direct_water_seasonlabour_total")).value = 
      (
        parseFloat(this.angForm.value.costs_direct_water_seasonlabour_owner) +      
        parseFloat(this.angForm.value.costs_direct_water_seasonlabour_permanent) +      
        parseFloat(this.angForm.value.costs_direct_water_seasonlabour_casual)
      ).toFixed(2);


   
      (<HTMLInputElement>document.getElementById("costs_direct_water_treatment_total")).value = 
      (
        parseFloat(this.angForm.value.costs_direct_water_treatment_owner) +      
        parseFloat(this.angForm.value.costs_direct_water_treatment_permanent) +      
        parseFloat(this.angForm.value.costs_direct_water_treatment_casual)
      ).toFixed(2);

     

      
      (<HTMLInputElement>document.getElementById("costs_direct_total")).value = 
      (
        parseFloat((<HTMLInputElement>document.getElementById("costs_direct_water_setuplabour_total")).value) +      
        parseFloat((<HTMLInputElement>document.getElementById("costs_direct_water_seasonlabour_total")).value) +  
        parseFloat((<HTMLInputElement>document.getElementById("costs_direct_water_treatment_total")).value) +  
        parseFloat(this.angForm.value.costs_direct_water_costs) +      
        parseFloat(this.angForm.value.costs_direct_water_treatment) +      
        parseFloat(this.angForm.value.costs_direct_water_pumping) +      
        parseFloat(this.angForm.value.costs_direct_water_other) +      
        parseFloat(this.angForm.value.costs_direct_water_fuel)
      ).toFixed(2);





    
      (<HTMLInputElement>document.getElementById("costs_other_pruning_total")).value = 
      (
        parseFloat(this.angForm.value.costs_other_pruning_hire) +
        parseFloat(this.angForm.value.costs_other_pruning_owner) +      
        parseFloat(this.angForm.value.costs_other_pruning_permanent) +      
        parseFloat(this.angForm.value.costs_other_pruning_casual)
      ).toFixed(2);
    
      (<HTMLInputElement>document.getElementById("costs_other_harvest_total")).value = 
      (
        parseFloat(this.angForm.value.costs_other_harvest_hire) +  
        parseFloat(this.angForm.value.costs_other_harvest_owner) +      
        parseFloat(this.angForm.value.costs_other_harvest_permanent) +      
        parseFloat(this.angForm.value.costs_other_harvest_casual)
      ).toFixed(2);

  
    


      (<HTMLInputElement>document.getElementById("costs_other_total")).value = 
      (
        parseFloat((<HTMLInputElement>document.getElementById("costs_other_pruning_total")).value) +      
        parseFloat((<HTMLInputElement>document.getElementById("costs_other_harvest_total")).value) +  
        parseFloat(this.angForm.value.costs_other_seed) +      
        parseFloat(this.angForm.value.costs_other_transport) 
      ).toFixed(2);

      

      (<HTMLInputElement>document.getElementById("costs_indirect_maintenance_total")).value = 
      (
        parseFloat(this.angForm.value.costs_indirect_maintenance_owner) +      
        parseFloat(this.angForm.value.costs_indirect_maintenance_permanent) +      
        parseFloat(this.angForm.value.costs_indirect_maintenance_casual) +      
        parseFloat(this.angForm.value.costs_indirect_maintenance_contractor)
      ).toFixed(2);


      



      (<HTMLInputElement>document.getElementById("costs_indirect_total")).value = 
      (
        parseFloat((<HTMLInputElement>document.getElementById("costs_indirect_maintenance_total")).value) +      
        parseFloat(this.angForm.value.costs_indirect_electricity) +      
        parseFloat(this.angForm.value.costs_indirect_fixedwater) +      
        parseFloat(this.angForm.value.costs_indirect_irrigation) +      
        parseFloat(this.angForm.value.costs_indirect_ongoingdepreciation) +
        parseFloat(this.angForm.value.costs_indirect_interest) +
        parseFloat(this.angForm.value.costs_indirect_bankfees) +
        parseFloat(this.angForm.value.costs_indirect_insurance) +
        parseFloat(this.angForm.value.costs_indirect_maintenance_equipment) +
        parseFloat(this.angForm.value.costs_indirect_watercarting) +
        parseFloat(this.angForm.value.costs_indirect_waterstorage)  
      ).toFixed(2);
        
 
      (<HTMLInputElement>document.getElementById("costs_total")).value = 
      (
        parseFloat((<HTMLInputElement>document.getElementById("costs_direct_total")).value) +      
        parseFloat((<HTMLInputElement>document.getElementById("costs_other_total")).value) +      
        parseFloat((<HTMLInputElement>document.getElementById("costs_indirect_total")).value) 
      ).toFixed(2);

      (<HTMLInputElement>document.getElementById("income_minus_costs")).value = 
      (
        parseFloat((<HTMLInputElement>document.getElementById("income_total")).value) -      
        parseFloat((<HTMLInputElement>document.getElementById("costs_total")).value)  
      ).toFixed(2);

      

      this.angForm.value.income_yield = (<HTMLInputElement>document.getElementById("income_yield")).value;
      this.angForm.value.income_total = (<HTMLInputElement>document.getElementById("income_total")).value;
      this.angForm.value.costs_direct_water_setuplabour_total = (<HTMLInputElement>document.getElementById("costs_direct_water_setuplabour_total")).value
      this.angForm.value.costs_direct_water_seasonlabour_total = (<HTMLInputElement>document.getElementById("costs_direct_water_seasonlabour_total")).value;
      this.angForm.value.costs_direct_water_treatment_total =       (<HTMLInputElement>document.getElementById("costs_direct_water_treatment_total")).value ;
      this.angForm.value.costs_direct_total =       (<HTMLInputElement>document.getElementById("costs_direct_total")).value;
      this.angForm.value.costs_other_pruning_total =       (<HTMLInputElement>document.getElementById("costs_other_pruning_total")).value;
      this.angForm.value.costs_other_harvest_total =    (<HTMLInputElement>document.getElementById("costs_other_harvest_total")).value;
      this.angForm.value.costs_other_total = (<HTMLInputElement>document.getElementById("costs_other_total")).value;
      this.angForm.value.costs_indirect_maintenance_total =       (<HTMLInputElement>document.getElementById("costs_indirect_maintenance_total")).value ;
      this.angForm.value.costs_indirect_total = (<HTMLInputElement>document.getElementById("costs_indirect_total")).value;
      this.angForm.value.costs_total =      (<HTMLInputElement>document.getElementById("costs_total")).value;
      this.angForm.value.income_minus_costs =      (<HTMLInputElement>document.getElementById("income_minus_costs")).value;










      (<HTMLInputElement>document.getElementById("costs_indirect_maintenance_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("costs_indirect_maintenance_total")).value);
      (<HTMLInputElement>document.getElementById("costs_indirect_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("costs_indirect_total")).value);
      (<HTMLInputElement>document.getElementById("costs_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("costs_total")).value);
      (<HTMLInputElement>document.getElementById("income_minus_costs")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("income_minus_costs")).value);



      (<HTMLInputElement>document.getElementById("costs_other_pruning_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("costs_other_pruning_total")).value);
      (<HTMLInputElement>document.getElementById("costs_other_harvest_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("costs_other_harvest_total")).value);
      (<HTMLInputElement>document.getElementById("costs_other_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("costs_other_total")).value);



      (<HTMLInputElement>document.getElementById("costs_direct_water_setuplabour_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("costs_direct_water_setuplabour_total")).value);
      (<HTMLInputElement>document.getElementById("costs_direct_water_seasonlabour_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("costs_direct_water_seasonlabour_total")).value);
      (<HTMLInputElement>document.getElementById("costs_direct_water_treatment_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("costs_direct_water_treatment_total")).value);
      (<HTMLInputElement>document.getElementById("costs_direct_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("costs_direct_total")).value);

      (<HTMLInputElement>document.getElementById("income_yield")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("income_yield")).value);
      (<HTMLInputElement>document.getElementById("income_total")).value = this.numberWithCommas((<HTMLInputElement>document.getElementById("income_total")).value);
    


    

   
 
    






 }

 clearApi(){

 
    this._snackBar.open(this.angForm.value.name + ' probe data refreshing','OK',{
      duration: 20 * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });

    this.dataService.cropclearapi(this.id)
    .subscribe(
    data => {

      this._snackBar.open(this.angForm.value.name + ' probe data refreshed','OK',{
        duration: 20 * 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
  
    },
    error => {
      this._snackBar.open(this.angForm.value.name + ' probe data refreshed','OK',{
        duration: 20 * 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
    
 

 }

 deleteform()
 {
  if (confirm('Are you sure you want to delete field ' + this.angForm.value.name + '' )){
    this.dataService.cropdelete(this.id)
    .subscribe(
    data => {
      this._snackBar.open('Field ' + this.angForm.value.name + ' deleted','OK',{
      duration: 20 * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
    this.router.navigate(['../fields']);
  
    },
    error => {
    });
    
  }
 }
 toggleHelp(){this.DisplayHelp = !this.DisplayHelp}


  saveform(copy)
  {

    var cropName = this.angForm.value.name 
    var strMode = ' added'
    var strError = ''


    if (this.angForm.value.name  == null || this.angForm.value.name == ""){
      strError = strError + 'Field name\n'
    }

    if (this.angForm.value.farmid  == null || this.angForm.value.farmid == ""){
      strError = strError + 'Farm / Season\n'
    }

    if (this.angForm.value.planting  == null || this.angForm.value.planting == ""){
      strError = strError + 'Planting date\n'
    }

    if (this.angForm.value.harvest  == null || this.angForm.value.harvest == ""){
      strError = strError + 'Expected / actual harvest date\n'
    }  
    
    if (this.angForm.value.api_key  == null || this.angForm.value.api_key == ""){
      strError = strError + 'API key\n'
    }        
    if (this.angForm.value.api_logger  == null || this.angForm.value.api_logger == ""){
      strError = strError + 'API logger\n'
    }        
    if (this.angForm.value.api_userid  == null || this.angForm.value.api_userid == ""){
      strError = strError + 'API user id\n'
    }    
    if (this.angForm.value.fieldarea  == null || this.angForm.value.fieldarea == ""){
      strError = strError + 'Field area\n'
    }  

    

    if (strError != ""){
      alert("Please complete required fields\n\n" + strError)
      return
    }

    if(this.mode == "Add" || copy){
      if(copy){
        cropName = cropName + ' (COPY)'
        strMode = ' copied'
      }


      this.dataService.cropadd(cropName,this.angForm.value.farmid, this.angForm.value.fieldarea, this.angForm.value.crop, this.angForm.value.cropvariety, this.angForm.value.seedrate, this.angForm.value.targetcrop, 
        this.angForm.value.plantspacingacross, 
        this.angForm.value.plantspacingdown, this.angForm.value.soiltype0_30cm, this.angForm.value.soiltype30_60cm, this.angForm.value.prewetting, this.angForm.value.planting, this.angForm.value.emergence50, this.angForm.value.emergence100, this.angForm.value.initiation, this.angForm.value.bulkingdate, this.angForm.value.canopyclosure, this.angForm.value.senescence, this.angForm.value.harvest, this.angForm.value.cropageatharvest, this.angForm.value.typeofirrigation, this.angForm.value.pivotspans, this.angForm.value.maxflowrate, this.angForm.value.pivotpressure, this.angForm.value.applicationrateatspeed, this.angForm.value.pivotlength, this.angForm.value.nozzletype, this.angForm.value.distributionuniformity, this.angForm.value.speedduration, this.angForm.value.applicationrate, this.angForm.value.siteid, this.angForm.value.loggerid, this.angForm.value.probetype, this.angForm.value.probelength, this.angForm.value.coordinatesforprobelat, this.angForm.value.coordinatesforprobelong, this.angForm.value.probetoplantdistance, this.angForm.value.probetoemitterdistance, this.angForm.value.probeinstalldate, this.angForm.value.datarecordingperiod, this.angForm.value.installmethod, this.angForm.value.deinstallationdate,
        this.angForm.value.crop_yield,
          this.angForm.value.crop_price,
          this.angForm.value.income_yield,
          this.angForm.value.income_watertrade,
          this.angForm.value.income_total,
          this.angForm.value.costs_direct_water_setuplabour_owner,
          this.angForm.value.costs_direct_water_setuplabour_permanent,
          this.angForm.value.costs_direct_water_setuplabour_casual,
          this.angForm.value.costs_direct_water_setuplabour_total,
          this.angForm.value.costs_direct_water_seasonlabour_owner,
          this.angForm.value.costs_direct_water_seasonlabour_permanent,
          this.angForm.value.costs_direct_water_seasonlabour_casual,
          this.angForm.value.costs_direct_water_seasonlabour_total,
          this.angForm.value.costs_direct_water_costs,
          this.angForm.value.costs_direct_water_treatment,
          this.angForm.value.costs_direct_water_treatment_owner,
          this.angForm.value.costs_direct_water_treatment_permanent,
          this.angForm.value.costs_direct_water_treatment_casual,
          this.angForm.value.costs_direct_water_treatment_total,
          this.angForm.value.costs_direct_water_pumping,
          this.angForm.value.costs_direct_water_other,
          this.angForm.value.costs_direct_water_fuel,
          this.angForm.value.costs_direct_total,
          this.angForm.value.costs_other_seed,
          this.angForm.value.costs_other_pruning_hire,
          this.angForm.value.costs_other_pruning_owner,
          this.angForm.value.costs_other_pruning_permanent,
          this.angForm.value.costs_other_pruning_casual,
          this.angForm.value.costs_other_pruning_total,
          this.angForm.value.costs_other_harvest_hire,
          this.angForm.value.costs_other_harvest_owner,
          this.angForm.value.costs_other_harvest_permanent,
          this.angForm.value.costs_other_harvest_casual,
          this.angForm.value.costs_other_harvest_total,
          this.angForm.value.costs_other_transport,
          this.angForm.value.costs_other_total,
          this.angForm.value.costs_indirect_electricity,
          this.angForm.value.costs_indirect_fixedwater,
          this.angForm.value.costs_indirect_irrigation,
          this.angForm.value.costs_indirect_newdepreciation,
          this.angForm.value.costs_indirect_ongoingdepreciation,
          this.angForm.value.costs_indirect_interest,
          this.angForm.value.costs_indirect_bankfees,
          this.angForm.value.costs_indirect_insurance,
          this.angForm.value.costs_indirect_maintenance_equipment,
          this.angForm.value.costs_indirect_maintenance_owner,
          this.angForm.value.costs_indirect_maintenance_permanent,
          this.angForm.value.costs_indirect_maintenance_casual,
          this.angForm.value.costs_indirect_maintenance_contractor,
          this.angForm.value.costs_indirect_maintenance_total,
          this.angForm.value.costs_indirect_watercarting,
          this.angForm.value.costs_indirect_waterstorage,
          this.angForm.value.costs_indirect_total,
          this.angForm.value.costs_total,
          this.angForm.value.income_minus_costs,
          this.angForm.value.api_key,
          this.angForm.value.api_logger,
          this.angForm.value.api_userid
        )
      .subscribe(
      data => {
   
        this._snackBar.open(this.angForm.value.name + ' ' + strMode + ' : Fetching probe data','OK',{
          duration: 60 * 1000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });

        this.router.navigate(['fields/' + data.Id + '/refresh']);
        


  



      //this.router.navigate(['dashboard']);

      },
      error => {
      });


    }else{

      this.dataService.cropupdate(

          this.angForm.value.name,
          this.angForm.value.farmid, 
          this.angForm.value.fieldarea, 
          this.angForm.value.crop, this.angForm.value.cropvariety, this.angForm.value.seedrate, this.angForm.value.targetcrop, this.angForm.value.plantspacingacross, 
          this.angForm.value.plantspacingdown, this.angForm.value.soiltype0_30cm, this.angForm.value.soiltype30_60cm, this.angForm.value.prewetting, 
          this.angForm.value.planting, 
          this.angForm.value.emergence50,  
          this.angForm.value.emergence100,  
          this.angForm.value.initiation, 
          this.angForm.value.bulkingdate,  
          this.angForm.value.canopyclosure,  
          this.angForm.value.senescence,  
          this.angForm.value.harvest,  
          this.angForm.value.cropageatharvest, this.angForm.value.typeofirrigation, this.angForm.value.pivotspans, this.angForm.value.maxflowrate, this.angForm.value.pivotpressure, this.angForm.value.applicationrateatspeed, this.angForm.value.pivotlength, this.angForm.value.nozzletype, this.angForm.value.distributionuniformity, this.angForm.value.speedduration, this.angForm.value.applicationrate, this.angForm.value.siteid, this.angForm.value.loggerid, this.angForm.value.probetype, this.angForm.value.probelength, this.angForm.value.coordinatesforprobelat, this.angForm.value.coordinatesforprobelong, this.angForm.value.probetoplantdistance, this.angForm.value.probetoemitterdistance, 
          this.angForm.value.probeinstalldate, this.angForm.value.datarecordingperiod, 
          this.angForm.value.installmethod, this.angForm.value.deinstallationdate,
          this.angForm.value.crop_yield,
          this.angForm.value.crop_price,
          this.angForm.value.income_yield,
          this.angForm.value.income_watertrade,
          this.angForm.value.income_total,
          this.angForm.value.costs_direct_water_setuplabour_owner,
          this.angForm.value.costs_direct_water_setuplabour_permanent,
          this.angForm.value.costs_direct_water_setuplabour_casual,
          this.angForm.value.costs_direct_water_setuplabour_total,
          this.angForm.value.costs_direct_water_seasonlabour_owner,
          this.angForm.value.costs_direct_water_seasonlabour_permanent,
          this.angForm.value.costs_direct_water_seasonlabour_casual,
          this.angForm.value.costs_direct_water_seasonlabour_total,
          this.angForm.value.costs_direct_water_costs,
          this.angForm.value.costs_direct_water_treatment,
          this.angForm.value.costs_direct_water_treatment_owner,
          this.angForm.value.costs_direct_water_treatment_permanent,
          this.angForm.value.costs_direct_water_treatment_casual,
          this.angForm.value.costs_direct_water_treatment_total,
          this.angForm.value.costs_direct_water_pumping,
          this.angForm.value.costs_direct_water_other,
          this.angForm.value.costs_direct_water_fuel,
          this.angForm.value.costs_direct_total,
          this.angForm.value.costs_other_seed,
          this.angForm.value.costs_other_pruning_hire,
          this.angForm.value.costs_other_pruning_owner,
          this.angForm.value.costs_other_pruning_permanent,
          this.angForm.value.costs_other_pruning_casual,
          this.angForm.value.costs_other_pruning_total,
          this.angForm.value.costs_other_harvest_hire,
          this.angForm.value.costs_other_harvest_owner,
          this.angForm.value.costs_other_harvest_permanent,
          this.angForm.value.costs_other_harvest_casual,
          this.angForm.value.costs_other_harvest_total,
          this.angForm.value.costs_other_transport,
          this.angForm.value.costs_other_total,
          this.angForm.value.costs_indirect_electricity,
          this.angForm.value.costs_indirect_fixedwater,
          this.angForm.value.costs_indirect_irrigation,
          this.angForm.value.costs_indirect_newdepreciation,
          this.angForm.value.costs_indirect_ongoingdepreciation,
          this.angForm.value.costs_indirect_interest,
          this.angForm.value.costs_indirect_bankfees,
          this.angForm.value.costs_indirect_insurance,
          this.angForm.value.costs_indirect_maintenance_equipment,
          this.angForm.value.costs_indirect_maintenance_owner,
          this.angForm.value.costs_indirect_maintenance_permanent,
          this.angForm.value.costs_indirect_maintenance_casual,
          this.angForm.value.costs_indirect_maintenance_contractor,
          this.angForm.value.costs_indirect_maintenance_total,
          this.angForm.value.costs_indirect_watercarting,
          this.angForm.value.costs_indirect_waterstorage,
          this.angForm.value.costs_indirect_total,
          this.angForm.value.costs_total,
          this.angForm.value.income_minus_costs,
          this.angForm.value.api_key,
          this.angForm.value.api_logger,
          this.angForm.value.api_userid,
          this.id)
        .subscribe(
        data => {
          this._snackBar.open('Field ' + this.angForm.value.name + ' updated','OK',{
            duration: 20 * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
        

        },
        error => {

        

        });
    }
  }

 
  }

  //TABLES
  export interface apidataApi {
    apidata: apidata[];
    columns: [];
    total_count: number;
  }
  export interface Food {
    value: string;
    viewValue: string;
  }
  export interface Group {
    value: string;
    viewValue: string;
  }
  export interface apidata {
    id: string;
    name: string;
    crop: string;
    cropvariety: string;
    plantingdate: string;
  }
  
  
  export class apidata {
    constructor(private _httpClient: HttpClient) {}
  
    getapidata(sort: string, order: string, page: number, apitable: string, cropid:number): Observable<apidataApi> {
  
   
      const href = 'https://waterlink.ruciak.net/api/apidata.php';
      const requestUrl =
          `${href}?orderby=${sort}&orderdirection=${order}&page=${page}&table=${apitable}&cropid=${cropid}`;
          
  
      return this._httpClient.get<apidataApi>(requestUrl,{ headers: {'token':localStorage.getItem('token')}});
    }
  }
  
   