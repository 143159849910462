<div class="container">
  


      
      <div class="row">
        <div class="col-9">
          <h2><button [routerLink]="['/dashboard']"  mat-raised-button >Dashboard</button> <button [routerLink]="['/users']"  mat-raised-button >Users</button> Edit User</h2>
        </div>
        <div class="col-3 text-right">
          <button mat-raised-button color="primary">Save</button>
          <button mat-button [routerLink]="['/users']" type="button" >Cancel</button>
        </div>
      </div>
  
 

      <mat-tab-group>
        <form [formGroup]="angForm" (ngSubmit)="postdata(angForm)" autocomplete="off" >
        <mat-tab label="Details">


  <mat-form-field class="full-width">
    <mat-label>Name</mat-label>
    <input matInput   formControlName="name"  placeholder="Name">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Email</mat-label>
    <input matInput   formControlName="email"  placeholder="Email">
  </mat-form-field>

 
  <mat-form-field class="full-width">
    <mat-label>Active</mat-label>
    <mat-select formControlName="active" >
      <mat-option value="0">No</mat-option>
      <mat-option value="1">Yes</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Admin</mat-label>
    <mat-select formControlName="admin" >
      <mat-option value="0">No</mat-option>
      <mat-option value="1">Yes</mat-option>
    </mat-select>
  </mat-form-field>

</mat-tab>
</form>
<mat-tab label="Admin">

  <br><br>
  <button mat-raised-button color="outline" (click)="deleteform()">Delete User</button>
  <br><br>
</mat-tab>

</mat-tab-group>

 


</div>
 
 