import {HttpClient} from '@angular/common/http';
import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';

 

/**
 * @title Table retrieving data through HTTP
 */
@Component({
  selector: 'app-apidata',
  templateUrl: './apidata.component.html',
  styleUrls: ['./apidata.component.css']
})



export class apidataComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
 
  displayedColumns: string[] = [];
  apidata: apidata | null;
  data: apidata[] = [];
  selectedapi: "";
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  selected = 'readings';



  foods: Food[] = [
    {value: 'alerts', viewValue: 'API Alerts'},
    {value: 'readings', viewValue: 'API Readings Daily'},
    {value: 'readings_wind', viewValue: 'API Readings Wind'},
    {value: 'readings_forcast', viewValue: 'API Readings Forcast'},
    {value: 'readings_alerts', viewValue: 'API Readings Alerts'},    
    {value: 'readings_alerts_graph', viewValue: 'API Readings Alerts Graph'},      
  ];

  transform(value:string): string {
    let first = value.substr(0,1).toUpperCase();
    return first + value.substr(1); 
  }

  changeValue(value:any){
 
    let selectedItem:any;
    selectedItem = this.foods.filter(item => item.value == value)[0]
    this.selected = selectedItem.value;
    this.displayedColumns =  [];
    this.paginator.pageIndex = 0;
    this.sort.active = "id";
     this.sort.direction = "desc";
    this.ngAfterViewInit();
  }

  constructor(private _httpClient: HttpClient) {}

  ngAfterViewInit() {
 
    this.apidata = new apidata(this._httpClient);
    

    // If the crop changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = false;
          return this.apidata!.getapidata(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.selected, "");
        }),
        map(data => {
          if (data["error"] != null){
            window.location.href = '/#/login?returnUrl=%2Fdashboard';
          }
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
     
          this.resultsLength = data.total_count;

          if (this.displayedColumns.length == 0){
            for (var index = 0; index < data.columns.length; ++index) {
              console.log(data.columns[index]);
              this.displayedColumns.push(data.columns[index]);
            }
          }
        
          return data.apidata;
        }),
        catchError(() => {
          this.isLoadingResults = false;

          window.location.href = '/#/login?returnUrl=%2Fdashboard';
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }
}

export interface apidataApi {
  apidata: apidata[];
  columns: [];
  total_count: number;
}
export interface Food {
  value: string;
  viewValue: string;
}
export interface apidata {
  id: string;
  name: string;
  crop: string;
  cropvariety: string;
  plantingdate: string;
}


export class apidata {
  constructor(private _httpClient: HttpClient) {}

  getapidata(sort: string, order: string, page: number, apitable: string, cropid:string): Observable<apidataApi> {

 
    const href = 'https://waterlink.ruciak.net/api/apidata_admin.php';
    const requestUrl =
        `${href}?orderby=${sort}&orderdirection=${order}&page=${page}&table=${apitable}&cropid=${cropid}`;
 

    return this._httpClient.get<apidataApi>(requestUrl,{ headers: {'token':localStorage.getItem('token')}});
  }
}
