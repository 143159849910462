<div class="container" id="container" style="display:none;">
  <div class="row">
    <div class="col-9">
<h2><button [routerLink]="['/dashboard']"  mat-raised-button >Dashboard</button> Farms</h2>
</div>
<div class="col-3 text-right">
  <button  [routerLink]="['/farms/add']" mat-raised-button color="primary">Add Farm</button>

</div>
</div>
<div class="example-container mat-elevation-z8">
    <div class="example-loading-shade"
         *ngIf="isLoadingResults || isRateLimitReached">
      <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
 
    </div>
  
    <div class="the-table-container">
  
      <table mat-table [dataSource]="data" class="example-table"
             matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
        <!-- Number Column -->
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let row">{{row.number}}</td>
        </ng-container>
  
        <!-- Title Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>id</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>
  
        <!-- State Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let row">
            <a [title]="row.name + ' details'" [routerLink]="['/farms', row.id]">
              {{ row.name }}
            </a>
             </td>
        </ng-container>
  
        <!-- Created Column -->
 

        <ng-container matColumnDef="customername">
          <th mat-header-cell *matHeaderCellDef>Customer</th>
          <td mat-cell *matCellDef="let row">{{row.customername}}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let row">{{row.email}}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Phone</th>
          <td mat-cell *matCellDef="let row">{{row.phone}}</td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let row">{{row.address}}</td>
        </ng-container>
        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef>Size</th>
          <td mat-cell *matCellDef="let row">{{row.size}}</td>
        </ng-container>




        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  
    <mat-paginator [length]="resultsLength" [pageSize]="30"></mat-paginator>
  </div>
</div>
