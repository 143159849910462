<div class="container" style="max-width: 400px;"><h2 class="text-center">Registration</h2>

<form [formGroup]="angForm" (ngSubmit)="postdata(angForm)" autocomplete="off" >
    <mat-form-field class="full-width">
        <mat-label>Name</mat-label>
        <input matInput   formControlName="name"  placeholder="Name">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input matInput   formControlName="email"  placeholder="Email">
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Password</mat-label>
        <input matInput  type="password"  formControlName="password"  >
      </mat-form-field>
      <button mat-raised-button color="primary">Register</button>
 
</form></div>

 