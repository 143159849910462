
 

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { UsersComponent } from './admin/users/users.component';
import { UsereditorComponent } from './admin/usereditor/usereditor.component';
import { FarmsComponent } from './admin/farms/farms.component';
import { FarmeditorComponent } from './admin/farmeditor/farmeditor.component';
import { CropsComponent } from './admin/crops/crops.component';
import { cropeditorComponent } from './admin/cropeditor/cropeditor.component';
import { SeasonsComponent } from './admin/seasons/seasons.component';
import { SeasoneditorComponent } from './admin/seasoneditor/seasoneditor.component';
import { apidataComponent } from './admin/apidata/apidata.component';

import { FormsModule,ReactiveFormsModule } from '@angular/forms'; 
 
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatBadgeModule} from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSidenavModule} from '@angular/material/sidenav';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';




@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    ReportsComponent,
    UsersComponent,
    UsereditorComponent,
    FarmsComponent,
    FarmeditorComponent,
    CropsComponent,
    cropeditorComponent,
    SeasonsComponent,
    SeasoneditorComponent,
    apidataComponent, SafePipe
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTableModule,MatSidenavModule,
    MatSortModule,MatSnackBarModule,MatDatepickerModule,MatTabsModule,MatGridListModule,MatTooltipModule,
    MatMenuModule,MatFormFieldModule,MatInputModule,MatNativeDateModule,MatButtonModule,MatSelectModule,MatBadgeModule,MatExpansionModule
   
  ],
  providers: [ MatDatepickerModule,   FormsModule],
  bootstrap: [AppComponent]
})
export class AppModule { }

 


 