import { Component, OnInit } from '@angular/core';
import { FormGroup,  FormBuilder, Validators} from '@angular/forms';
import { first } from 'rxjs/operators';

import { ApiService } from '../../api.service';
 
 
 
import { Router, ActivatedRoute } from '@angular/router';
import {MatSnackBar,  MatSnackBarHorizontalPosition,  MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
@Component({
  selector: 'app-farmeditor',
  templateUrl: './farmeditor.component.html',
  styleUrls: ['./farmeditor.component.css']
})
export class FarmeditorComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  id: number;
  mode: string  = "Edit";
  angForm: FormGroup;
  displayNavigation: boolean = false;
  displayWelcome : boolean = false;
  constructor(private fb: FormBuilder,private dataService: ApiService, private router: Router, private route: ActivatedRoute,private _snackBar: MatSnackBar ) {

    this.angForm = this.fb.group({
      name: ['', Validators.required],
      customername: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],      
      size: ['', Validators.required]
      });

      
           //Check for Farm
           this.dataService.farmGet('').pipe(first()).subscribe(data => { 
            if (data.total_count == 0){
              this.displayWelcome = true;
            }else{
              this.displayNavigation = true;
             }
          });
  
  
      }
 
  
  ngOnInit() {

 
    const routeParams = this.route.snapshot.paramMap;
 
    if(routeParams.get('id') == null || routeParams.get('id') == "add"){
      this.mode = "Add"

    }else{
      this.id = Number(routeParams.get('id'));
   


  this.dataService.farmGet(this.id)
    .pipe(first())
    .subscribe(
    data => {

      this.angForm = this.fb.group({
        name: [data.farms[0].name, Validators.required],
        customername: [data.farms[0].customername, Validators.required],
        address: [data.farms[0].address, Validators.required],
        email: [data.farms[0].email, Validators.required],
        phone: [data.farms[0].phone, Validators.required],
        size: [data.farms[0].size, Validators.required]
        });
    },
    error => {
      alert("Farm not found")
    });
  }
 
    
  }

  deleteform()
 {
if (confirm('Are you sure you want to delete farm ' + this.angForm.value.name + ' including all linked seasons and fields' )){
  this.dataService.farmdelete(this.id)
  .subscribe(
  data => {
    
    this._snackBar.open('Farm ' + this.angForm.value.name + ' deleted','OK',{
      duration: 20 * 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  this.router.navigate(['farms']);

  },
  error => {
  });
  
}
 }

 saveform(copy)
 {

  var farmName = this.angForm.value.name 
  var strMode = ' added'

  var strError = ''


  if (this.angForm.value.name  == null || this.angForm.value.name == ""){
    strError = strError + 'Farm name\n'
  }
  if (this.angForm.value.customername  == null || this.angForm.value.customername == ""){
    strError = strError + 'Contact name\n'
  }
  if (this.angForm.value.address  == null || this.angForm.value.address == ""){
    strError = strError + 'Address\n'
  }
  if (this.angForm.value.email  == null || this.angForm.value.email == ""){
    strError = strError + 'Email\n'
  }
  if (this.angForm.value.phone  == null || this.angForm.value.phone == ""){
    strError = strError + 'Phone\n'
  }
  if (this.angForm.value.size  == null || this.angForm.value.size == "" || !stringIsNumber(this.angForm.value.size) ){
    strError = strError + 'Size\n'
  }  

  if (strError != ""){
    alert("Please complete required fields\n\n" + strError)
    return
  }


    if(this.mode == "Add" || copy){

      if(copy){
        farmName = farmName + ' (COPY)'
        strMode = ' copied'
      }

      this.dataService.farmadd(farmName,this.angForm.value.customername,this.angForm.value.address,this.angForm.value.email,this.angForm.value.phone,this.angForm.value.size)
      .subscribe(
        data => {
          this._snackBar.open('Farm ' + this.angForm.value.name + ' added','OK',{
            duration: 20 * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
        
        if (this.displayWelcome){
          this.router.navigate(['fields/add']);
        }else{
          this.router.navigate(['farms']);
        }
        

      },
      error => {
      });


    }else{

      this.dataService.farmupdate(this.angForm.value.name,this.angForm.value.customername,this.angForm.value.address,this.angForm.value.email,this.angForm.value.phone,this.angForm.value.size, this.id)
        .subscribe(
        data => {
          this._snackBar.open('Farm ' + this.angForm.value.name + ' updated','OK',{
            duration: 20 * 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
        this.router.navigate(['farms']);

        },
        error => {
        });
    }
  }
  
 
  }

  function stringIsNumber(s) {
    var x = +s;  
    return x.toString() === s;
}
 