import { Injectable, Output, EventEmitter } from '@angular/core';
 
 
 
@Injectable({
providedIn: 'root'
})

export class BadgeService {

currentBadgeDashboard:string = "";
currentBadgeDashboardHidden:boolean = true;
currentBadgeCrops:string = "";
currentBadgeCropsHidden:boolean = true;
currentBadgeFarms = "";
currentBadgeFarmsHidden:boolean = true;


@Output() updateBadges: EventEmitter<any> = new EventEmitter();
@Output() updateAdmin: EventEmitter<any> = new EventEmitter();

 
constructor() { 

}

public UpdateAdmin() {
    this.updateAdmin.emit(true);
}
public BadgeDashboard() {
    return this.currentBadgeDashboard;
}
 
public BadgeDashboardHidden() {
    return this.currentBadgeDashboardHidden;
}
public BadgeCrops() {
    return this.currentBadgeCrops;
}
 
public BadgeCropsHidden() {
    return this.currentBadgeCropsHidden;
}

public BadgeFarms() {
    return this.currentBadgeFarms;
}
 
public BadgeFarmsHidden() {
    return this.currentBadgeFarmsHidden;
}

 
public setBadgeDashboard(msg:string) {
    if (msg == null){
        this.currentBadgeDashboardHidden = true;
    }else{
        this.currentBadgeDashboardHidden = false;
        this.currentBadgeDashboard = msg;
    }
    this.updateBadges.emit(true);
}

public setBadgeCrops( msg:string) {
    if (msg == ""){
        this.currentBadgeCropsHidden = true;
    }else{
        this.currentBadgeCropsHidden = false;
        this.currentBadgeCrops = msg;
    }
    this.updateBadges.emit(true);
}
 
public setBadgeFarms(msg:string) {
    if (msg == ""){
        this.currentBadgeFarmsHidden = true;
    }else{
        this.currentBadgeFarmsHidden = false;
        this.currentBadgeFarms = msg;
    }
    this.updateBadges.emit(true);
}
 
 
 
}